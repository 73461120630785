import React, { useState, useRef, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { supabase } from "../../client";
import "./SignUpPage.css";
import {
  faEnvelope,
  faPhone,
  faUser,
  faLock,
  faEyeSlash,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import BoxInput from "../../components/BoxInput/BoxInput";
import { CurrentDataContext } from "../../App";
import EnDict from "../../assets/dict/EnDict.json";
import CnDict from "../../assets/dict/CnDict.json";
import ArDict from "../../assets/dict/ArDict.json";
import Modal from "react-modal";
import TermsConditions from "../TermsConditions/TermsConditions";
import PrivacyPolicy from "../PrivacyPolicy/PrivacyPolicy";

const SignUpPage = () => {
  const { data, setData } = useContext(CurrentDataContext);
  let navigate = useNavigate();
  const [signUpType, setSignUpType] = useState("email");
  const [visible, setVisible] = useState(false);
  const handleRightIconClick = () => {
    setVisible(!visible);
  };
  const [visible2, setVisible2] = useState(false);
  const handleRightIconClick2 = () => {
    setVisible2(!visible2);
  };
  const [accept, setAccept] = useState(false);
  const [phone, setPhone] = useState();

  const emailRef = useRef();
  const [emptyEmail, setEmptyEmail] = useState();
  const [errorEmail, setErrorEmail] = useState();
  const phoneRef = useRef();
  const [emptyPhone, setEmptyPhone] = useState();
  const passwordRef = useRef();
  const [emptyPassword, setEmptyPassword] = useState();
  const retypePasswordRef = useRef();
  const [emptyRetypePassword, setEmptyRetypePassword] = useState();
  const [errorPassword, setErrorPassword] = useState();
  const fullNameRef = useRef();
  const [emptyFullName, setEmptyFullName] = useState();
  const acceptRef = useRef();
  const [errorAccept, setErrorAccept] = useState();

  const [tncModalOpen, setTncModalOpen] = useState(false);
  const [ppModalOpen, setPpModalOpen] = useState(false);

  const [language, setLanguage] = useState();
  const [dict, setDict] = useState();

  useEffect(() => {
    console.log("lang", data.language);
    // setLanguage(data.language);
    switch (data.language) {
      case "en":
        setDict(EnDict);
        break;
      case "zh":
        setDict(CnDict);
        break;
      case "ar":
        setDict(ArDict);
        break;
      default:
        setDict(EnDict);
    }
  }, [data.language]);

  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    password: "",
    retypePassword: "",
    phone: "",
  });

  // function validatePassword(password) {
  //   const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[A-Za-z\d\W_]{8,}$/;
  //   return regex.test(password);
  // }

  function validatePassword(password) {
    const lengthRegex = /^.{8,}$/;
    const capitalLetterRegex = /[A-Z]/;
    const smallLetterRegex = /[a-z]/;
    const symbolRegex = /[\W_]/;

    if (!lengthRegex.test(password)) {
      alert("Password should contain at least 8 characters.");
      return false;
    }
    // if (!capitalLetterRegex.test(password)) {
    //   alert("Password should contain at least 1 capital letter.");
    //   return false;
    // }
    // if (!smallLetterRegex.test(password)) {
    //   alert("Password should contain at least 1 small letter.");
    //   return false;
    // }
    // if (!symbolRegex.test(password)) {
    //   alert("Password should contain at least 1 symbol.");
    //   return false;
    // }

    return true; // All requirements met
  }

  function handleChange(event) {
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [event.target.name]: event.target.value,
      };
    });
  }

  async function handleSubmit(e) {
    e.preventDefault();
    let value = {};

    if (signUpType === "email") {
      if (formData.email === "") {
        setEmptyEmail(true);
        // emailRef.current.focus();
        return;
      }
      let emailRegex = /[a-z0-9]+@\w+\.[a-z]{2,3}/;
      if (!emailRegex.test(formData.email)) {
        // invalid email
        setErrorEmail(true);
        // emailRef.current.focus();
        return;
      }
      value["email"] = formData.email;
    } else {
      formData.phone = phone;
      let phoneRegex = /^\+\d{6}/;
      if (formData.phone === "" || !phoneRegex.test(formData.phone)) {
        setEmptyPhone(true);
        // emailRef.current.focus();
        return;
      }
      // return;
      // try {
      //   const { data, error } = await supabase.auth.signUp({
      //     phone: formData.phone,
      //     password: formData.password,
      //     options: {
      //       data: {
      //         full_name: formData.fullName,
      //       },
      //     },
      //   });
      //   if (error) throw error;
      //   alert("Input otp received");
      //   // setTemp((prev) => ({
      //   //   ...prev,
      //   //   phone: formData.phone,
      //   // }));
      //   navigate("/verify");
      // } catch (error) {
      //   alert(error);
      // }
      value["phone"] = formData.phone;
    }

    if (formData.password === "") {
      setEmptyPassword(true);
      return;
    }

    if (!validatePassword(formData.password)) {
      setEmptyPassword(true);
      return;
    }
    if (formData.retypePassword === "") {
      setEmptyRetypePassword(true);
      return;
    }
    if (formData.password !== formData.retypePassword) {
      setErrorPassword(true);
      return;
    }
    value["password"] = formData.password;

    if (formData.fullName === "") {
      setEmptyFullName(true);
      return;
    }
    value["options"] = { data: { full_name: formData.fullName } };

    if (accept === false) {
      // alert("Please agree terms and conditions");
      setErrorAccept(true);
      return;
    }

    try {
      const { resp, error } = await supabase.auth.signUp(value);
      if (error) throw error;
      setData((prev) => ({
        ...prev,
        signUpType: signUpType,
        phone: formData.phone,
        email: formData.email,
        options: formData.options,
      }));
      navigate("/verify");
    } catch (error) {
      alert(error);
    }

    // return
    // try {
    //   const { data, error } = await supabase.auth.signUp({
    //     email: formData.email,
    //     password: formData.password,
    //     options: {
    //       data: {
    //         full_name: formData.fullName,
    //       },
    //     },
    //   });
    //   if (error) throw error;
    //   alert("Check your email for verification link");
    //   navigate("/");
    // } catch (error) {
    //   alert(error);
    // }
  }

  return (
    <div className="signup__wrapper">
      <h1>{dict?.sign_up_2}</h1>
      <div className="login__button-side">
        <button
          onClick={() => {
            setSignUpType("email");
          }}
        >
          {dict?.email}
        </button>
        <button
          onClick={() => {
            setSignUpType("phone");
          }}
        >
          {dict?.phone}
        </button>
      </div>
      <form className="signup__form-wrapper" onSubmit={handleSubmit}>
        {signUpType === "phone" && (
          <p className="app__alert-red">{dict?.sms_advice}</p>
        )}

        {signUpType === "email" && (
          <BoxInput
            leftIcon={faEnvelope}
            name={"email"}
            placeholder={dict?.email}
            type={"text"}
            handleChange={handleChange}
            ref={emailRef}
            error={emptyEmail ? emptyEmail : errorEmail}
            errorText={
              emptyEmail ? dict?.error_enter_email : dict?.error_invalid_email
            }
            setError={emptyEmail ? setEmptyEmail : setErrorEmail}
          ></BoxInput>
        )}
        {signUpType === "phone" && (
          <BoxInput
            leftIcon={faPhone}
            name={"phone"}
            type="text"
            placeholder={dict?.phone}
            handleChange={handleChange}
            phone={phone}
            setPhone={setPhone}
            ref={phoneRef}
            error={emptyPhone}
            errorText={dict?.error_fill_in_valid_phone_number}
            setError={setEmptyPhone}
          ></BoxInput>
        )}
        <BoxInput
          leftIcon={faLock}
          rightIcon={visible ? faEye : faEyeSlash}
          name={"password"}
          type={visible ? "text" : "password"}
          placeholder={dict?.password_req}
          handleChange={handleChange}
          handleRightIconClick={handleRightIconClick}
          ref={passwordRef}
          error={emptyPassword}
          errorText={dict?.error_password_requirements}
          setError={setEmptyPassword}
        ></BoxInput>
        <BoxInput
          leftIcon={faLock}
          rightIcon={visible2 ? faEye : faEyeSlash}
          name={"retypePassword"}
          type={visible2 ? "text" : "password"}
          placeholder={dict?.retype_password}
          handleChange={handleChange}
          handleRightIconClick={handleRightIconClick2}
          ref={retypePasswordRef}
          error={errorPassword ? errorPassword : emptyRetypePassword}
          errorText={
            errorPassword
              ? dict?.error_retype_same_password
              : dict?.error_fillin_retype_password
          }
          setError={errorPassword ? setErrorPassword : setEmptyRetypePassword}
        ></BoxInput>
        <BoxInput
          leftIcon={faUser}
          name={"fullName"}
          type={"text"}
          placeholder={dict?.full_name}
          handleChange={handleChange}
          ref={fullNameRef}
          error={emptyFullName}
          errorText={dict?.error_fillin_full_name}
          setError={setEmptyFullName}
        ></BoxInput>

        <div className="signup__checkbox">
          <label>
            <input
              className="app__checkbox"
              type="checkbox"
              checked={accept}
              onChange={() => {
                setAccept(!accept);
                setErrorAccept(false);
              }}
            />
            {dict?.accept_letbabytalk}
            <span
              onClick={() => setTncModalOpen(true)}
              style={{ color: "blue", cursor: "pointer" }}
            >
              {" "}
              {dict?.termsnregulation}{" "}
            </span>{" "}
            {dict?.and}
            <span
              onClick={() => setPpModalOpen(true)}
              style={{ color: "blue", cursor: "pointer" }}
            >
              {" "}
              {dict?.privacypolicy}{" "}
            </span>
          </label>
          {errorAccept && (
            <div className="invalid-feedback">{dict?.error_accept_tnr_pp}</div>
          )}
        </div>

        <button className="login__button-wrapper" type="submit">
          {dict?.sign_up_2}
        </button>
      </form>
      <div style={{ margin: "1rem" }}>
        {dict?.already_have_account} <Link to="/signin">{dict?.sign_in}</Link>
      </div>
      <Modal
        isOpen={tncModalOpen}
        ariaHideApp={false}
        onRequestClose={() => setTncModalOpen(false)}
        contentLabel="Terms and Conditions"
      >
        <div>
          <TermsConditions setTncModalOpen={setTncModalOpen}></TermsConditions>
        </div>
      </Modal>

      <Modal
        isOpen={ppModalOpen}
        ariaHideApp={false}
        onRequestClose={() => setPpModalOpen(false)}
        contentLabel="Privacy Policy"
      >
        <div>
          {/* Include your privacy policy content here */}
          <PrivacyPolicy setPpModalOpen={setPpModalOpen}></PrivacyPolicy>
        </div>
      </Modal>
    </div>
  );
};

export default SignUpPage;
