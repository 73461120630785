import React, { useState, useContext, useMemo, useEffect } from "react";
import { supabase } from "../../client";
import { Link, useNavigate } from "react-router-dom";
import "./VerificationPage.css";
import Otp from "../../components/Otp/Otp";
import Countdown from "react-countdown";
import { CurrentDataContext } from "../../App";
import EnDict from "../../assets/dict/EnDict.json";
import CnDict from "../../assets/dict/CnDict.json";
import ArDict from "../../assets/dict/ArDict.json";

const VerificationPage = ({ setToken }) => {
  const { data, setData } = useContext(CurrentDataContext);

  const [otpError, setOtpError] = useState(false);

  let countdownTimer = 120;
  const [seconds, setSeconds] = useState(countdownTimer);

  const [language, setLanguage] = useState();
  const [dict, setDict] = useState();

  let navigate = useNavigate();
  const [otp, setOtp] = useState("");

  const [formData, setFormData] = useState({
    phone: "",
    email: "",
    password: "",
  });

  useEffect(() => {
    console.log("lang", data.language);
    // setLanguage(data.language);
    switch (data.language) {
      case "en":
        setDict(EnDict);
        break;
      case "zh":
        setDict(CnDict);
        break;
      case "ar":
        setDict(ArDict);
        break;
      default:
        setDict(EnDict);
    }
  }, [data.language]);

  async function handleSubmit(e) {
    e.preventDefault();
    console.log("otp", otp);
    if (otp.length !== 6) {
      setOtpError(true);
      alert(dict.fill_in_otp);
      return;
    }
    // return;
    let value = {};
    if (data.signUpType === "phone") {
      value["phone"] = data.phone;
      value["type"] = "sms";
    } else {
      value["email"] = data.email;
      value["type"] = "email";
    }
    value["token"] = otp;

    try {
      const { data: resp, error } = await supabase.auth.verifyOtp(value);
      if (error) throw error;
      // alert("Verified successful");
      setToken(resp);

      if (data.from === "forgetpassword") {
        sessionStorage.setItem("token", JSON.stringify(resp));
        navigate("/reset");
      } else {
        setData((prev) => ({
          ...prev,
          newUser: true,
        }));
        navigate("/homepage");
      }
    } catch (error) {
      alert(error);
    }
  }

  async function handleSend() {
    setSeconds(countdownTimer);
    let value = {};
    if (data.signUpType === "email") {
      value["type"] = "signup";
      value["email"] = data.email;
    } else {
      value["type"] = "sms";
      value["phone"] = data.phone;
    }
    try {
      const { data: resp, error } = await supabase.auth.resend(value);
      alert(dict.sent);
    } catch (error) {
      alert(error);
    }
  }

  useEffect(() => {
    const timer = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [seconds]);

  return (
    <div className="verify__wrapper">
      <h1>{dict?.verification_code}</h1>
      <h3>
        {dict?.we_have_sent_the_code}{" "}
        {data.signUpType === "email" ? data.email : data.phone}
      </h3>

      <form className="login__form-wrapper" onSubmit={handleSubmit}>
        <Otp
          value={otp}
          setValue={setOtp}
          otpError={otpError}
          setOtpError={setOtpError}
        ></Otp>
        <button className="login__button-wrapper" type="submit">
          {dict?.submit}
        </button>
      </form>

      <div>
        <h3>
          {dict?.did_not_received}

          {seconds === 0 ? (
            <Link onClick={handleSend}>{dict?.send_again} </Link>
          ) : (
            <>
              {" "}
              {dict?.please_wait} {seconds} {dict?.sec}
            </>
          )}
        </h3>
      </div>
    </div>
  );
};

export default VerificationPage;
