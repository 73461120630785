import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "@aws-amplify/ui-react/styles.css";
import Navbar from "./components/Navbar/Navbar";
import EditRecordPage from "./container/EditRecordPage/EditRecordPage";
import { createContext, useState, useEffect } from "react";
import text from "./assets/img/text.svg";
import SignInPage from "./container/SignInPage/SignInPage";
import SignUpPage from "./container/SignUpPage/SignUpPage";
import VerificationPage from "./container/VerificationPage/VerificationPage";
import ForgetPasswordPage from "./container/ForgetPasswordPage/ForgetPasswordPage";
import ResetPassword from "./container/ForgetPasswordPage/ResetPassword";
import HomePage from "./container/HomePage/HomePage";
import BabyPage from "./container/BabyPage/BabyPage";
import AddPage from "./container/AddPage/AddPage";
import ResPage from "./container/ResPage/ResPage";
import { useLocalStorage } from "./hooks/useLocalStorage";
import GuestHomePage from "./container/GuestHomePage/GuestHomePage";
import GuestResPage from "./container/GuestResPage/GuestResPage";
import TermsConditions from "./container/TermsConditions/TermsConditions";
import PrivacyPolicy from "./container/PrivacyPolicy/PrivacyPolicy";
// I18n.setLanguage("zh");
// const usera = await AuthUser.currentAuthenticatedUser({ bypassCache: true });

export const CurrentDataContext = createContext();
const key = "auth";

function App() {
  const [token, setToken] = useState();

  console.log("navi", navigator);

  if ("serviceWorker" in navigator) {
    window.addEventListener("load", () => {
      navigator.serviceWorker
        .register("/service-worker.js")
        .then((registration) => {
          console.log(
            "Service Worker registered with scope:",
            registration.scope
          );
          registration.onupdatefound = () => {
            const installingWorker = registration.installing;
            installingWorker.onstatechange = () => {
              if (installingWorker.state === "installed") {
                if (navigator.serviceWorker.controller) {
                  const updateNotification = document.createElement("div");
                  updateNotification.style.position = "fixed";
                  updateNotification.style.bottom = "0";
                  updateNotification.style.width = "100%";
                  updateNotification.style.backgroundColor = "#f8d7da";
                  updateNotification.style.color = "#721c24";
                  updateNotification.style.textAlign = "center";
                  updateNotification.style.padding = "1em";
                  updateNotification.innerHTML = `
                  <p>New version available. <button id="refresh">Refresh</button></p>
                `;
                  document.body.appendChild(updateNotification);
                  document
                    .getElementById("refresh")
                    .addEventListener("click", () => {
                      if (registration.waiting) {
                        registration.waiting.postMessage("SKIP_WAITING");
                      }
                    });
                }
              }
            };
          };
        })
        .catch((error) => {
          console.log("Service Worker registration failed:", error);
        });
    });

    // Ensure refresh is only called once.
    let refreshing;
    navigator.serviceWorker.addEventListener("controllerchange", () => {
      if (refreshing) return;
      window.location.reload();
      refreshing = true;
    });
  }

  const { getItem, setItem, removeItem } = useLocalStorage(key);
  console.log("token", token);

  if (token) {
    setItem(token);
    sessionStorage.setItem("token", JSON.stringify(token));
  }

  useEffect(() => {
    const past_token = getItem();
    if (past_token) {
      console.log("yes", past_token);
      setToken(past_token);
    }
    // if (sessionStorage.getItem("token")) {
    //   let data = JSON.parse(sessionStorage.getItem("token"));
    //   setToken(data);
    // }
  }, []);

  console.log("detect browser language", navigator.language);
  const detectedLanguage =
    navigator.language.split("-")[0] == "zh" ? "zh" : "en";
  // const detectedLanguage = "en";
  const [data, setData] = useState({ language: detectedLanguage });
  const [showGreet, setShowGreet] = useState(true);
  // const [hideGreet, setHideGreet] = useState(false);
  const [hideGreet, setHideGreet] = useState(true);
  const [language, setLanguage] = useState();
  const [loginMechanisms, setLoginMechanisms] = useState("email");
  const [change, setChange] = useState(false);
  // const [user, setUser] = useState(undefined);
  console.log("app: ", data);

  useEffect(() => {
    console.log("lang", data.language);
    // I18n.setLanguage(data.language);
    setLanguage(data.language);
  }, [data.language]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setShowGreet(false);
  //     setTimeout(() => {
  //       setHideGreet(true);
  //     }, 1000);
  //   }, 2000);
  // }, []);

  return (
    <div className={`app__root ${data.language == "ar" ? "arabic" : ""}`}>
      {!hideGreet ? (
        <img
          className={`bg ${showGreet ? "" : "hideBg"}`}
          src={text}
          alt="logo animation"
        />
      ) : (
        <div className={`app__root ${data.language == "ar" ? "arabic" : ""}`}>
          <CurrentDataContext.Provider value={{ data, setData }}>
            {/* {data.user == undefined && <LanguageBar></LanguageBar>} */}
            <div className="App">
              <BrowserRouter>
                <Navbar token={token} removeItem={removeItem}></Navbar>
                <Routes>
                  <Route
                    path="ghomepage"
                    element={<GuestHomePage></GuestHomePage>}
                  ></Route>
                  <Route
                    path="gresult"
                    element={<GuestResPage></GuestResPage>}
                  ></Route>
                  <Route
                    path="tnc"
                    element={<TermsConditions></TermsConditions>}
                  ></Route>
                  <Route
                    path="pp"
                    element={<PrivacyPolicy></PrivacyPolicy>}
                  ></Route>
                  {token && (
                    <Route
                      path="*"
                      element={
                        <HomePage
                          token={token}
                          change={change}
                          setChange={setChange}
                        ></HomePage>
                      }
                    ></Route>
                  )}
                  {
                    // <Route
                    //   path="*"
                    //   element={<MainPage user={{}}></MainPage>}
                    // ></Route
                    <Route
                      path="*"
                      element={<SignInPage setToken={setToken}></SignInPage>}
                    ></Route>
                  }
                  {token && (
                    <Route
                      path="baby"
                      element={
                        <BabyPage
                          token={token}
                          change={change}
                          setChange={setChange}
                        ></BabyPage>
                      }
                    ></Route>
                  )}
                  {token && (
                    <Route
                      path="add"
                      element={
                        <AddPage
                          token={token}
                          change={change}
                          setChange={setChange}
                        ></AddPage>
                      }
                    ></Route>
                  )}
                  {token && (
                    <Route
                      path="result"
                      element={<ResPage token={token}></ResPage>}
                    ></Route>
                  )}
                  {token && (
                    <Route
                      path="edit"
                      element={
                        <EditRecordPage
                          user={{}}
                          change={change}
                          setChange={setChange}
                        ></EditRecordPage>
                      }
                    ></Route>
                  )}
                  {/* <Route
                    path="create"
                    element={<CreatePage user={{}}></CreatePage>}
                  ></Route>
                  <Route
                    path="result"
                    element={<ResultPage user={{}}></ResultPage>}
                  ></Route>
                  <Route
                    path="edit"
                    element={<EditPage user={{}}></EditPage>}
                  ></Route> */}
                  {token && (
                    <Route
                      path="homepage"
                      element={
                        <HomePage
                          token={token}
                          change={change}
                          setChange={setChange}
                        ></HomePage>
                      }
                    ></Route>
                  )}
                  <Route
                    path="signin"
                    element={<SignInPage setToken={setToken}></SignInPage>}
                  ></Route>
                  <Route
                    path="signup"
                    element={<SignUpPage></SignUpPage>}
                  ></Route>
                  <Route
                    path="verify"
                    element={
                      <VerificationPage setToken={setToken}></VerificationPage>
                    }
                  ></Route>
                  <Route
                    path="forget"
                    element={
                      <ForgetPasswordPage
                        setToken={setToken}
                      ></ForgetPasswordPage>
                    }
                  ></Route>
                  {token && (
                    <Route
                      path="reset"
                      element={<ResetPassword token={token}></ResetPassword>}
                    ></Route>
                  )}
                </Routes>
              </BrowserRouter>
            </div>
          </CurrentDataContext.Provider>
        </div>
      )}
    </div>
  );
}

export default App;
