import React, { useState } from "react";
import "./Otp.css";

const Otp = ({ value, setValue, otpError, setOtpError }) => {
  const [otp, setOtp] = useState(new Array(6).fill(""));

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return false; // ensure only numbers are allowed
    const newOtp = [...otp];
    newOtp[index] = element.value;
    setOtp(newOtp);
    console.log(newOtp);
    setValue(newOtp.join(""));
    setOtpError(false);

    // Auto-focus to next input on fill
    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };
  return (
    <div className={`otp-inputs ${otpError ? "otp-error" : ""}`}>
      {otp.map((data, index) => {
        return (
          <input
            className="otp-input"
            type="number"
            name="otp"
            maxLength="1"
            key={index}
            value={data}
            onChange={(e) => handleChange(e.target, index)}
            onFocus={(e) => e.target.select()}
          />
        );
      })}
    </div>
  );
};

export default Otp;
