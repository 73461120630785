import React, { useRef, useState, useEffect, useContext } from "react";
import EnDict from "../../assets/dict/EnDict.json";
import CnDict from "../../assets/dict/CnDict.json";
import ArDict from "../../assets/dict/ArDict.json";
import { CurrentDataContext } from "../../App";
import { useLocation } from "react-router-dom";

const PrivacyPolicy = ({ setPpModalOpen }) => {
  const { data, setData } = useContext(CurrentDataContext);
  const [dict, setDict] = useState();
  // const location = useLocation();
  // const queryParams = new URLSearchParams(location.search);
  // const language = queryParams.get("lang") || "en";

  // useEffect(() => {
  //   setData((prev) => ({
  //     ...prev,
  //     language: language,
  //   }));
  // }, []);

  useEffect(() => {
    console.log("lang", data.language);
    // setLanguage(data.language);
    switch (data.language) {
      case "en":
        setDict(EnDict);
        break;
      case "zh":
        setDict(CnDict);
        break;
      case "ar":
        setDict(ArDict);
        break;
      default:
        setDict(EnDict);
    }
  }, [data.language]);

  function handleClose() {
    // window.open("about:blank", "_self");
    // window.close();
    setPpModalOpen(false);
  }
  return (
    <div className={`${data.language == "ar" ? "arabic" : ""}`}>
      <h1>{dict?.privacy_policy_letbabytalk}</h1>
      <h2>{dict?.your_privacy}</h2>
      <p>{dict?.pp0}</p>

      <h3>{dict?.pp1}</h3>
      {/* <p>{dict?.pp1c}</p> */}
      <ul>
        <li>
          <strong>{dict?.pp1c_1}</strong> {dict?.pp1c_1c}
        </li>
        <li>
          <strong>{dict?.pp1c_2}</strong> {dict?.pp1c_2c}
        </li>
      </ul>

      <h3>{dict?.pp2}</h3>
      <p>{dict?.pp2c}</p>
      <ul>
        <li>{dict?.pp2c_1}</li>
        <li>{dict?.pp2c_2}</li>
        <li>{dict?.pp2c_3}</li>
        <li>{dict?.pp2c_4}</li>
      </ul>

      <h3>{dict?.pp3}</h3>
      <p>{dict?.pp3c}</p>

      <h3>{dict?.pp4}</h3>
      <p>{dict?.pp4c}</p>

      <h3>{dict?.pp5}</h3>
      <p>{dict?.pp5c}</p>
      <button className="login__button-wrapper" onClick={() => handleClose()}>
        {dict?.agree}
      </button>
    </div>
  );
};

export default PrivacyPolicy;
