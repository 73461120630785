import React, { useState, useRef, useEffect, useContext } from "react";
import "./EditRecordPage.css";
import { CurrentDataContext } from "../../App";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Joyride from "react-joyride";
import { Link, useNavigate } from "react-router-dom";

import { faHouse } from "@fortawesome/free-solid-svg-icons";
import { generateClient } from "aws-amplify/api";
import PercentItem from "../../components/PercentItem/PercentItem";
import EnDict from "../../assets/dict/EnDict.json";
import CnDict from "../../assets/dict/CnDict.json";
import ArDict from "../../assets/dict/ArDict.json";
import Modal from "react-modal";
import { supabase } from "../../client";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import Switch from "react-switch";

const client = generateClient();

const EditRecordPage = ({ user }) => {
  const { data, setData } = useContext(CurrentDataContext);
  const [dict, setDict] = useState(data.language === "zh" ? CnDict : EnDict);

  useEffect(() => {
    switch (data.language) {
      case "en":
        setDict(EnDict);
        break;
      case "zh":
        setDict(CnDict);
        break;
      case "ar":
        setDict(ArDict);
        break;
      default:
        setDict(EnDict);
    }
  }, [data.language]);

  const TYPE_LIST = [
    dict?.pain,
    dict?.hungry,
    dict?.breathing_challenge,
    dict?.hearing_challenge,
  ];

  const EXPLAIN_LIST = [
    dict?.explain_pain,
    dict?.explain_hungry,
    dict?.explain_bc,
    dict?.explain_hc,
  ];

  const REASON_LIST = [
    dict?.sleepiness,
    dict?.hunger_milk,
    dict?.hunger_solid_food,
    dict?.diaper_change_urine,
    dict?.diaper_change_bowel_movement,
    dict?.difficulty_breathing,
    dict?.internal_pain,
    dict?.external_pain,
    dict?.physical_discomfort,
    dict?.unmet_needs,
    dict?.lack_of_security,
  ];

  const currentId = 0;
  const username = user.username;
  const [selectedBaby, setSelectedBaby] = useState("");
  const [nickname, setNickname] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const [location, setLocation] = useState("");
  const [locationOther, setLocationOther] = useState("");
  const navigate = useNavigate();
  const [babies, setBabies] = useState();
  const mediaFile = useRef();
  const [prob, setProb] = useState([]);
  const [open, setOpen] = useState(false);
  const myRef = useRef(null);

  const [isCorrect, setIsCorrect] = useState("wrong"); // 'correct', 'wrong'
  const [wrongReasons, setWrongReasons] = useState(""); // Array of wrong reasons
  const [feedback, setFeedback] = useState("");
  const [otherReasons, setOtherReasons] = useState("");
  const [done, setDone] = useState(false);

  const [emptyIsCorrect, setEmptyIsCorrect] = useState(false);
  const [emptyWrongReasons, setEmptyWrongReasons] = useState(false);
  const [emptyOthers, setEmptyOthers] = useState(false);
  const [recordUrl, setRecordUrl] = useState("");
  const [emptyLocation, setEmptyLocation] = useState(false);
  const [emptyLocationOther, setEmptyLocationOther] = useState(false);

  const [showPrediction, setShowPrediction] = useState(data?.showPrediction);

  const locationRef = useRef();
  const locationOtherRef = useRef();

  const [runTour, setRunTour] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  console.log("see", isCorrect);

  const {
    getItem: getShowPredictionItem,
    setItem: setShowPredictionItem,
    removeItem: removeShowPredictionItem,
  } = useLocalStorage("showPrediction");

  const handleChange = (nextChecked) => {
    setShowPrediction(nextChecked);
    setShowPredictionItem(nextChecked);
  };

  const steps = [
    {
      target: ".createpage__wrapper",
      content: dict?.guide_editresultpage,
      placement: "bottom",
      disableBeacon: true,
    },
    {
      target: ".switch-section",
      content: dict?.guide_show_prediction,
      placement: "bottom",
      disableBeacon: true,
    },
    // {
    //   target: ".prob__wrapper",
    //   content: dict?.guide_result,
    //   placement: "bottom",
    //   disableBeacon: true,
    // },
    {
      target: ".res__input-group",
      content: dict?.guide_information,
      placement: "bottom",
      disableBeacon: true,
    },
    {
      target: ".editpage__audio-wrapper",
      content: dict?.guide_audio,
      placement: "bottom",
      disableBeacon: true,
    },
  ];

  const handleBabyChange = (event) => {
    const selectedIndex = event.target.value;
    setSelectedBaby(babies[selectedIndex]);
    setNickname(babies[selectedIndex]?.name);
    setAge(babies[selectedIndex]?.age);
    setGender(babies[selectedIndex]?.gender);
  };

  const handleIsCorrectChange = (e) => {
    setIsCorrect(e.target.value);
    setEmptyIsCorrect(false);
    setWrongReasons("");
  };

  const handleWrongReasonsChange = (e) => {
    setWrongReasons(e.target.value);
    setEmptyWrongReasons(false);
    setOtherReasons("");
  };

  const handleFeedbackChange = (e) => {
    setFeedback(e.target.value);
  };

  useEffect(() => {
    const currentRec = data.rec;
    console.log("currentrec: ", currentRec);
    setSelectedBaby(data.baby);
    setAge(currentRec?.age);
    setGender(currentRec?.gender);
    setNickname(currentRec?.name);
    const prob_ = currentRec?.prediction
      ? JSON.parse(currentRec?.prediction)
      : [];
    setProb(prob_);

    let locationDisplay = "aaaa";
    if (currentRec.location.includes("others")) {
      const loc = currentRec.location.split("/");
      setLocation("others");
      setLocationOther(loc[1]);
    } else {
      setLocation(currentRec.location);
    }

    setFeedback(currentRec?.feedback);
    setIsCorrect(currentRec?.correct);
    if (isCorrect === "wrong" && currentRec.reason) {
      if (currentRec.reason.includes("others")) {
        const rea = currentRec.reason.split("/");
        setWrongReasons("others");
        setOtherReasons(rea[1]);
      } else {
        setWrongReasons(currentRec?.reason);
      }
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    let reason = wrongReasons;
    if (wrongReasons === "others") {
      reason = wrongReasons + "/" + otherReasons;
    }

    let loc = location;
    if (loc === "others") {
      loc = loc + "/" + locationOther;
    }

    try {
      const { data: resp, error } = await supabase
        .from("Record")
        .update({
          reason: reason,
          correct: isCorrect,
          location: loc,
          updated_at: new Date(),
        })
        .eq("id", data.rec.id)
        .select();

      if (error) {
        throw error;
      }
      navigate("/homepage");
      return resp;
    } catch (error) {
      console.error("Error adding item", error);
    } finally {
    }
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "auto",
    },
  };

  async function getRecordings() {
    try {
      let { data: url, error } = await supabase.storage
        .from("letbabytalk")
        .download(`${data?.userId}/${data.rec.recordings}`);

      if (error) {
        throw error;
      }
      return url;
    } catch (error) {
      console.error("Error adding item", error);
    } finally {
    }
  }

  useEffect(() => {
    if (data && data.rec && data.rec.recordings) {
      getRecordings().then((resp) => {
        setRecordUrl(URL.createObjectURL(resp));
      });
    }
  }, []);

  useEffect(() => {
    if (data.guide == true) {
      setRunTour(true);
      setData((prev) => ({
        ...prev,
        guide: false,
      }));
    }
  }, [data.guide]);

  const handleJoyrideCallback = (data) => {
    const { action, index, type } = data;

    if (type === "step:after" || type === "tooltip:next") {
      setStepIndex(index + (action === "prev" ? -1 : 1));
    }

    if (type === "tour:end") {
      setRunTour(false);
      setStepIndex(0);
    }
  };

  // Sort the probabilities from highest to lowest
  const sortedProbs = prob
    .map((p, index) => ({
      percent: p,
      type: TYPE_LIST[index],
      explain: EXPLAIN_LIST[index],
    }))
    .sort((a, b) => b.percent - a.percent);

  return (
    <div className="createpage__wrapper">
      <Joyride
        steps={steps}
        run={runTour}
        stepIndex={stepIndex}
        continuous
        showSkipButton
        callback={handleJoyrideCallback}
        locale={{
          back: dict?.back_step,
          close: dict?.close,
          last: dict?.last,
          next: dict?.next,
          open: dict?.open_dialog,
          skip: dict?.skip,
        }}
        styles={{
          options: {
            zIndex: 10000,
          },
        }}
      />
      <Modal
        isOpen={open}
        onClose={() => {
          setOpen(false);
        }}
        center
        showCloseIcon={false}
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
        style={customStyles}
        container={myRef.current}
      >
        <div
          className={`customModal__button-wrapper ${
            data.language == "ar" ? "arabic" : ""
          }`}
        >
          <p>{dict?.thank_you_feedback}</p>
          <button
            className="createpage__button"
            onClick={() => {
              navigate("/");
            }}
          >
            <FontAwesomeIcon color="black" icon={faHouse} size="1x" />{" "}
            <span>{dict?.home}</span>
          </button>
        </div>
      </Modal>
      <div className="editpage__wrapper">
        <h3>{dict?.edit_record}</h3>
        <form
          className="createpage__wrapper-form"
          onSubmit={(event) => handleSubmit(event)}
        >
          {/* <div className="form-group">
            <label>{dict?.profile}</label>
            <select
              name="profile"
              value={""}
              onChange={handleBabyChange}
              disabled
            >
              <option value={""}>{selectedBaby.name}</option>
              {babies?.map((baby, index) => (
                <option key={index} value={index}>
                  {baby.name}
                </option>
              ))}
            </select>
          </div> */}

          <div
            className="switch-section"
            style={{ justifyContent: "flex-start" }}
          >
            {dict?.show_prediction_result}
            <Switch
              onChange={handleChange}
              checked={showPrediction}
              className="react-switch"
            />
          </div>

          {showPrediction && (
            <div className="prob__wrapper">
              {sortedProbs.map((r, i) => (
                <PercentItem
                  isResult={true}
                  key={i}
                  name={r.type}
                  percent={r.percent * 100}
                  explain={r.explain}
                />
              ))}
            </div>
          )}
          <div className="editpage__audio-wrapper">
            <audio id="audio" src={recordUrl} controls></audio>
            {data.helper && (
              <h3>
                {dict?.value}: {data.rec ? data.rec.value : "-"}
              </h3>
            )}
          </div>

          <div className="res__input-group">
            <label>{dict?.location}</label>
            <select
              name="location"
              value={location}
              ref={locationRef}
              onChange={(e) => {
                setLocation(e.target.value);
                setEmptyLocation(false);
              }}
            >
              <option value="" disabled>
                {dict?.please_choose}
              </option>
              <option value="daycare_centre">{dict?.location_daycare}</option>
              <option value="home">{dict?.location_home}</option>
              <option value="hospital/clinic">{dict?.location_hc}</option>
              <option value="park/playground">{dict?.location_pp}</option>
              <option value="street">{dict?.location_street}</option>
              <option value="others">{dict?.others}</option>
            </select>
            {emptyLocation && (
              <div className="invalid-feedback">{dict?.error_please_state}</div>
            )}
          </div>
          {location === "others" && (
            <div className="res__input-group">
              <label>{dict?.please_state}</label>
              <input
                type="text"
                name="otherLocation"
                value={locationOther}
                ref={locationOtherRef}
                onChange={(e) => {
                  setEmptyLocationOther(false);
                  setLocationOther(e.target.value);
                }}
              />
              {emptyLocationOther && (
                <div className="invalid-feedback">
                  {dict?.error_please_state}
                </div>
              )}
            </div>
          )}
          {isCorrect === "wrong" && (
            <div className="input-group">
              <label>{dict?.reason}</label>
              <select value={wrongReasons} onChange={handleWrongReasonsChange}>
                <option value="" disabled>
                  {dict?.please_choose}
                </option>
                {REASON_LIST.map((type, index) => (
                  <option key={index} value={type}>
                    {type}
                  </option>
                ))}
                <option value="others">{dict?.others}</option>
              </select>
              {emptyWrongReasons && (
                <div className="invalid-feedback">
                  {dict?.error_please_select}
                </div>
              )}
            </div>
          )}
          {wrongReasons === "others" && (
            <div className="input-group">
              <label>{dict?.please_state}</label>
              <input
                type="text"
                name="otherReason"
                value={otherReasons}
                onChange={(e) => {
                  setEmptyOthers(false);
                  setOtherReasons(e.target.value);
                }}
              />
              {emptyOthers && (
                <div className="invalid-feedback">{dict?.please_state}</div>
              )}
            </div>
          )}
          <div className="editpage__button-wrapper">
            <button
              className="editpage__button"
              type="button"
              onClick={(event) => {
                handleSubmit(event);
              }}
            >
              <span>{dict?.submit}</span>
            </button>
            <button
              className="editpage__button-red"
              type="button"
              onClick={() => {
                navigate("/homepage");
              }}
            >
              <span>{dict?.home}</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditRecordPage;
