import React, { useState, useRef, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { supabase } from "../../client";
import "./ForgetPasswordPage.css";
import {
  faEnvelope,
  faPhone,
  faUser,
  faLock,
  faEyeSlash,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import BoxInput from "../../components/BoxInput/BoxInput";
import { CurrentDataContext } from "../../App";
import EnDict from "../../assets/dict/EnDict.json";
import CnDict from "../../assets/dict/CnDict.json";
import ArDict from "../../assets/dict/ArDict.json";

const ResetPassword = () => {
  let navigate = useNavigate();
  const { data, setData } = useContext(CurrentDataContext);

  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    password: "",
    phone: "",
  });

  const [language, setLanguage] = useState();
  const [dict, setDict] = useState();

  useEffect(() => {
    console.log("lang", data.language);
    // setLanguage(data.language);
    switch (data.language) {
      case "en":
        setDict(EnDict);
        break;
      case "zh":
        setDict(CnDict);
        break;
      case "ar":
        setDict(ArDict);
        break;
      default:
        setDict(EnDict);
    }
  }, [data.language]);

  const [visible, setVisible] = useState(false);
  const handleRightIconClick = () => {
    setVisible(!visible);
  };
  const [visible2, setVisible2] = useState(false);
  const handleRightIconClick2 = () => {
    setVisible2(!visible2);
  };

  // function validatePassword(password) {
  //   const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[A-Za-z\d\W_]{8,}$/;
  //   return regex.test(password);
  // }

  function validatePassword(password) {
    const lengthRegex = /^.{8,}$/;
    const capitalLetterRegex = /[A-Z]/;
    const smallLetterRegex = /[a-z]/;
    const symbolRegex = /[\W_]/;

    if (!lengthRegex.test(password)) {
      alert("Password should contain at least 8 characters.");
      return false;
    }
    // if (!capitalLetterRegex.test(password)) {
    //   alert("Password should contain at least 1 capital letter.");
    //   return false;
    // }
    // if (!smallLetterRegex.test(password)) {
    //   alert("Password should contain at least 1 small letter.");
    //   return false;
    // }
    // if (!symbolRegex.test(password)) {
    //   alert("Password should contain at least 1 symbol.");
    //   return false;
    // }

    return true; // All requirements met
  }

  const passwordRef = useRef();
  const [emptyPassword, setEmptyPassword] = useState();
  const retypePasswordRef = useRef();
  const [emptyRetypePassword, setEmptyRetypePassword] = useState();
  const [errorPassword, setErrorPassword] = useState();

  function handleChange(event) {
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [event.target.name]: event.target.value,
      };
    });
  }

  async function handleSubmit(e) {
    e.preventDefault();
    console.log(formData);
    if (formData.password === "") {
      setEmptyPassword(true);
      return;
    }

    if (!validatePassword(formData.password)) {
      setEmptyPassword(true);
      return;
    }
    if (formData.retypePassword === "") {
      setEmptyRetypePassword(true);
      return;
    }
    if (formData.password !== formData.retypePassword) {
      setErrorPassword(true);
      return;
    }

    try {
      const { data, error } = await supabase.auth.updateUser({
        password: formData.password,
      });
      if (error) throw error;
      setData((prev) => ({
        ...prev,
        from: "",
      }));
      alert("Reset succesfully.");
      navigate("/homepage");
    } catch (error) {
      alert(error);
    }
  }

  return (
    <div className="reset__wrapper">
      <h1>{dict?.reset_password}</h1>
      <h3>{dict?.set_new_password}</h3>
      <form className="reset__form-wrapper" onSubmit={handleSubmit}>
        <BoxInput
          leftIcon={faLock}
          rightIcon={visible ? faEye : faEyeSlash}
          name={"password"}
          type={visible ? "text" : "password"}
          placeholder={dict?.password_req}
          handleChange={handleChange}
          handleRightIconClick={handleRightIconClick}
          ref={passwordRef}
          error={emptyPassword}
          errorText={dict?.error_password_requirements}
          setError={setEmptyPassword}
        ></BoxInput>
        <BoxInput
          leftIcon={faLock}
          rightIcon={visible2 ? faEye : faEyeSlash}
          name={"retypePassword"}
          type={visible2 ? "text" : "password"}
          placeholder={dict?.retype_password}
          handleChange={handleChange}
          handleRightIconClick={handleRightIconClick2}
          ref={retypePasswordRef}
          error={errorPassword ? errorPassword : emptyRetypePassword}
          errorText={
            errorPassword
              ? dict?.error_retype_same_password
              : dict?.error_fillin_retype_password
          }
          setError={errorPassword ? setErrorPassword : setEmptyRetypePassword}
        ></BoxInput>
        <button className="login__button-wrapper" type="submit">
          {dict?.reset}
        </button>
      </form>
    </div>
  );
};

export default ResetPassword;
