import React, { useContext, useState, useRef, useEffect } from "react";
import "./ResPage.css";
import PercentItem from "../../components/PercentItem/PercentItem";
import { CurrentDataContext } from "../../App";
import { useNavigate } from "react-router-dom";
import EnDict from "../../assets/dict/EnDict.json";
import CnDict from "../../assets/dict/CnDict.json";
import ArDict from "../../assets/dict/ArDict.json";
import { supabase } from "../../client";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faSquareXmark,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import Switch from "react-switch";
import Joyride from "react-joyride";

import { useLocalStorage } from "../../hooks/useLocalStorage";

const ResPage = () => {
  const { data, setData } = useContext(CurrentDataContext);
  console.log("res", data);

  const [dict, setDict] = useState();

  const navigate = useNavigate();
  const [isCorrect, setIsCorrect] = useState("wrong"); // 'correct', 'wrong'
  const [wrongReasons, setWrongReasons] = useState(""); // Array of wrong reasons
  const [feedback, setFeedback] = useState("");
  const [otherReasons, setOtherReasons] = useState("");
  const [location, setLocation] = useState("");
  const [locationOther, setLocationOther] = useState("");
  const [toggleDisplay, setToggleDisplay] = useState(true);

  const myRef = useRef(null);

  const [emptyIsCorrect, setEmptyIsCorrect] = useState(false);
  const [emptyWrongReasons, setEmptyWrongReasons] = useState(false);
  const [emptyOthers, setEmptyOthers] = useState(false);
  const [emptyLocation, setEmptyLocation] = useState(false);
  const [emptyLocationOther, setEmptyLocationOther] = useState(false);

  const [recordUrl, setRecordUrl] = useState();

  const [showPrediction, setShowPrediction] = useState(data?.showPrediction);

  const steps = [
    {
      target: ".respage__wrapper",
      content: dict?.guide_resultpage,
      placement: "bottom",
      disableBeacon: true,
    },
    {
      target: ".switch-section",
      content: dict?.guide_show_prediction,
      placement: "bottom",
      disableBeacon: true,
    },
    // {
    //   target: ".result__wrapper",
    //   content: dict?.guide_result,
    //   placement: "bottom",
    //   disableBeacon: true,
    // },
    {
      target: ".information__form",
      content: dict?.guide_information,
      placement: "bottom",
      disableBeacon: true,
    },
    {
      target: ".res__audio",
      content: dict?.guide_audio,
      placement: "bottom",
      disableBeacon: true,
    },
  ];

  const isCorrectRef = useRef();
  const wrongReasonsRef = useRef();
  const othersRef = useRef();
  const locationRef = useRef();
  const locationOtherRef = useRef();

  const [runTour, setRunTour] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);

  const {
    getItem: getShowPredictionItem,
    setItem: setShowPredictionItem,
    removeItem: removeShowPredictionItem,
  } = useLocalStorage("showPrediction");

  const [open, setOpen] = useState(false);

  const handleIsCorrectChange = (e) => {
    setIsCorrect(e.target.value);
    setEmptyIsCorrect(false);
    setWrongReasons("");
  };

  const handleWrongReasonsChange = (e) => {
    setWrongReasons(e.target.value);
    setEmptyWrongReasons(false);
    setOtherReasons("");
  };

  const handleChange = (nextChecked) => {
    setShowPrediction(nextChecked);
    setShowPredictionItem(nextChecked);
  };

  const handleFeedbackChange = (e) => {
    setFeedback(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // if (true) {
    //   if (location == "") {
    //     setEmptyLocation(true);
    //     locationRef.current.focus();
    //     return;
    //   }

    //   if (location === "others" && locationOther === "") {
    //     setEmptyLocationOther(true);
    //     locationOtherRef.current.focus();
    //     return;
    //   }

    //   if (isCorrect === "") {
    //     setEmptyIsCorrect(true);
    //     isCorrectRef.current.focus();
    //     return;
    //   }

    //   if (isCorrect === "wrong" && wrongReasons === "") {
    //     setEmptyWrongReasons(true);
    //     wrongReasonsRef.current.focus();
    //     return;
    //   }

    //   if (wrongReasons === "others" && otherReasons === "") {
    //     setEmptyOthers(true);
    //     othersRef.current.focus();
    //     return;
    //   }
    // }

    let reason = wrongReasons;
    if (wrongReasons === "others") {
      reason = wrongReasons + "/" + otherReasons;
    }

    let loc = location;
    if (loc === "others") {
      loc = loc + "/" + locationOther;
    }

    try {
      const { data: resp, error } = await supabase
        .from("Record")
        .update({
          reason: reason,
          correct: isCorrect,
          location: loc,
          updated_at: new Date(),
        })
        .eq("id", data.record.id)
        .select();

      if (error) {
        throw error;
      }
      navigate("/homepage");
      return resp;
    } catch (error) {
      console.error("Error adding item", error);
    } finally {
    }
  };

  useEffect(() => {
    console.log("lang", data.language);
    // setLanguage(data.language);
    switch (data.language) {
      case "en":
        setDict(EnDict);
        break;
      case "zh":
        setDict(CnDict);
        break;
      case "ar":
        setDict(ArDict);
        break;
      default:
        setDict(EnDict);
    }
  }, [data.language]);

  async function getRecordings() {
    console.log("getting ", data?.record.recordings);
    try {
      let { data: url, error } = await supabase.storage
        .from("letbabytalk")
        .download(`${data?.record.user_id}/${data?.record.recordings}`);
      // .getPublicUrl(`recordings/${data?.record.recordings}`);
      //   const { data: resp, error } = await supabase.storage
      //     .from("letbabytalk")
      //     .download(`recordings/${data?.record.recordings}`);

      if (error) {
        throw error;
      }
      return url;
    } catch (error) {
      console.error("Error adding item", error);
    } finally {
    }
  }

  async function deleteRecords() {
    try {
      const { error } = await supabase
        .from("Record")
        .delete()
        .eq("id", data.record.id);

      if (error) {
        throw error;
      }
      // setChange(true);
      return data;
    } catch (error) {
      console.error("Error adding item", error);
    } finally {
    }
  }

  const removeRecordings = async (filename) => {
    try {
      //   setLoading(true);
      const filename = data?.record.recordings;
      console.log("delete:", filename);
      const { resp, error } = await supabase.storage
        .from("letbabytalk")
        .remove(`${data?.record.user_id}/${data?.record.recordings}`);
      // .remove(`recordings/${filename}`);
      if (error) {
        throw error;
      }
      return filename;
    } catch (error) {
      console.error("Error adding item", error);
    } finally {
      //   setLoading(false);
    }
  };

  const handleDelete = () => {
    deleteRecords();
    removeRecordings();
    navigate("/homepage");
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "auto",
    },
    overlay: {
      // backgroundColor: "transparent",
    },
  };

  useEffect(() => {
    getRecordings().then((resp) => {
      console.log("recordings?", resp);
      setRecordUrl(URL.createObjectURL(resp));
    });
  }, []);

  // const TYPE_LIST = [dict?.pain, dict?.hungry, dict?.asphyxia, dict?.deaf];
  const TYPE_LIST = [
    dict?.pain,
    dict?.hungry,
    dict?.breathing_challenge,
    dict?.hearing_challenge,
  ];

  const EXPLAIN_LIST = [
    dict?.explain_pain,
    dict?.explain_hungry,
    dict?.explain_bc,
    dict?.explain_hc,
  ];

  const REASON_LIST = [
    dict?.sleepiness,
    dict?.hunger_milk,
    dict?.hunger_solid_food,
    dict?.diaper_change_urine,
    dict?.diaper_change_bowel_movement,
    dict?.difficulty_breathing,
    dict?.internal_pain,
    dict?.external_pain,
    dict?.physical_discomfort,
    dict?.unmet_needs,
    dict?.lack_of_security,
  ];

  useEffect(() => {
    if (data.guide == true) {
      console.log("run");
      setRunTour(true);
      setData((prev) => ({
        ...prev,
        guide: false,
      }));
    }
  }, [data.guide]);

  const handleJoyrideCallback = (data) => {
    const { action, index, type } = data;
    console.log("act", action, index, type);

    if (type === "step:after" || type === "tooltip:next") {
      setStepIndex(index + (action === "prev" ? -1 : 1));
    }

    if (type === "tour:end") {
      setRunTour(false);
      setStepIndex(0);
    }
  };

  return (
    <div className="respage__wrapper">
      <Joyride
        steps={steps}
        run={runTour}
        stepIndex={stepIndex}
        continuous
        showSkipButton
        callback={handleJoyrideCallback}
        locale={{
          back: dict?.back_step,
          close: dict?.close,
          last: dict?.last,
          next: dict?.next,
          open: dict?.open_dialog,
          skip: dict?.skip,
        }}
        styles={{
          options: {
            zIndex: 10000,
          },
        }}
      />
      <Modal
        isOpen={open}
        onClose={() => setOpen(false)}
        center
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        showCloseIcon={true}
        style={customStyles}
        container={myRef.current}
      >
        <div className="customModal__button-wrapper">
          <p>{dict?.are_you_sure_delete}</p>
          <button
            className="createpage__button"
            onClick={() => {
              setOpen(false);
            }}
          >
            <FontAwesomeIcon color="black" icon={faSquareXmark} size="2x" />{" "}
            <span>{dict?.cancel}</span>
          </button>
          <button
            className="createpage__button"
            onClick={(rec) => {
              setOpen(false);
              handleDelete();
            }}
          >
            <FontAwesomeIcon color="black" icon={faTrash} size="2x" />
            <span>{dict?.delete}</span>
          </button>
        </div>
      </Modal>
      <div className="back__wrapper">
        <FontAwesomeIcon
          className="input__icon"
          color="black"
          icon={faArrowLeft}
          onClick={() => navigate("/homepage")}
          size="2x"
        />
      </div>
      <h1>{dict?.analyzed_result}</h1>
      <div className="switch-section">
        <label>
          {dict?.show_prediction_result}
          <Switch
            onChange={handleChange}
            checked={showPrediction}
            className="react-switch"
          />
        </label>
      </div>
      {showPrediction && (
        <>
          {data &&
          data.result &&
          data.result.data.probs.length > 0 &&
          data.result.data.probs[0].length > 0 ? (
            <div className="result__wrapper">
              {(() => {
                let result = data.result.data.probs[0];
                let threeTotal = 0;
                let resultList = TYPE_LIST.map((name, i) => {
                  let percent = 0;
                  let _percent = parseInt((result[i] * 100).toFixed(0));
                  if (i < TYPE_LIST.length - 1) {
                    threeTotal += _percent || 0;
                    percent = _percent;
                  } else {
                    percent = 100 - threeTotal;
                  }
                  return {
                    name,
                    percent,
                    explain: EXPLAIN_LIST[i],
                  };
                }).sort((a, b) => b.percent - a.percent);
                // Render the result here, for example:
                return (
                  <div>
                    {resultList.map((item, index) => (
                      <PercentItem
                        key={index}
                        name={item.name}
                        percent={item.percent}
                        isResult={true}
                        explain={item.explain}
                      ></PercentItem>
                    ))}
                  </div>
                );
              })()}
            </div>
          ) : (
            <p>{dict?.no_data_available}</p>
          )}
        </>
      )}
      <div className="information__form">
        {/* <div className="switch-section">
        {dict?.show_prediction_result}
        <Switch
          onChange={handleChange}
          checked={showPrediction}
          className="react-switch"
        />
      </div> */}
        <label>
          <span>{dict?.optional_fill_in}</span>
          <Switch
            onChange={() => setToggleDisplay(!toggleDisplay)}
            checked={toggleDisplay}
            className="react-switch"
          />
        </label>
      </div>
      <form className="gresultpage__form" onSubmit={handleSubmit}>
        {toggleDisplay && (
          <div>
            <div className="res__input-group">
              <label>
                {dict?.location}
                {/* <span className="app__label--required">*</span> */}
              </label>
              <select
                name="location"
                value={location}
                ref={locationRef}
                onChange={(e) => {
                  setLocation(e.target.value);
                  setEmptyLocation(false);
                }}
              >
                <option value="" disabled>
                  {dict?.please_choose}
                </option>
                <option value="daycare_centre">{dict?.location_daycare}</option>
                <option value="home">{dict?.location_home}</option>
                <option value="hospital/clinic">{dict?.location_hc}</option>
                <option value="park/playground">{dict?.location_pp}</option>
                <option value="street">{dict?.location_street}</option>
                <option value="others">{dict?.others}</option>
              </select>{" "}
              {/* <input
            type="text"
            name="location"
            ref={locationRef}
            value={location}
            onChange={(e) => setLocation(e.target.value)}
          /> */}
              {emptyLocation && (
                <div className="invalid-feedback">
                  {dict?.error_please_state}
                </div>
              )}
            </div>
            {location === "others" && (
              <div className="res__input-group">
                <label>
                  {dict?.please_state}
                  {/* <span className="app__label--required">*</span> */}
                </label>
                <input
                  type="text"
                  name="otherLocation"
                  value={locationOther}
                  ref={locationOtherRef}
                  onChange={(e) => {
                    setEmptyLocationOther(false);
                    setLocationOther(e.target.value);
                  }}
                />
                {emptyLocationOther && (
                  <div className="invalid-feedback">
                    {dict?.error_please_state}
                  </div>
                )}
              </div>
            )}

            {isCorrect === "wrong" && (
              <div className="input-group">
                <label>
                  {dict?.reason}
                  {/* <span className="app__label--required">*</span> */}
                </label>
                <select
                  value={wrongReasons}
                  ref={wrongReasonsRef}
                  onChange={handleWrongReasonsChange}
                >
                  <option value="" disabled>
                    {dict?.please_select}
                  </option>
                  {REASON_LIST.map((type) => (
                    <option value={type}>{type}</option>
                  ))}
                  <option value="others">{dict?.others}</option>
                </select>
                {emptyWrongReasons && (
                  <div className="invalid-feedback">
                    {dict?.error_please_select}
                  </div>
                )}
              </div>
            )}
            {wrongReasons === "others" && (
              <div className="res__input-group">
                <label>
                  {dict?.error_please_state}
                  {/* <span className="app__label--required">*</span> */}
                </label>
                <input
                  type="text"
                  name="otherReason"
                  value={otherReasons}
                  ref={othersRef}
                  onChange={(e) => {
                    setEmptyOthers(false);
                    setOtherReasons(e.target.value);
                  }}
                />
                {emptyOthers && (
                  <div className="invalid-feedback">
                    {dict?.error_please_state}
                  </div>
                )}
              </div>
            )}
          </div>
        )}
        <div className="respage__button-wrapper">
          <button
            className="respage__button"
            type="button"
            onClick={(event) => {
              handleSubmit(event);
            }}
          >
            <span>{dict?.submit}</span>
          </button>
          <button
            className="respage__button-red"
            type="button"
            onClick={() => {
              handleDelete();
            }}
          >
            <span>{dict?.delete}</span>
          </button>
        </div>
      </form>
      <div className="res__audio">
        <audio id="audio" src={recordUrl} controls></audio>
        {/* <MediaControl></MediaControl> */}
      </div>
    </div>
  );
};

export default ResPage;
