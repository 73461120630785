import { createClient } from "@supabase/supabase-js";

// const supabaseUrl = 'https://hjudnxozhddpxofegjug.supabase.co'
// const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImhqdWRueG96aGRkcHhvZmVnanVnIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NzAxMzE5NDcsImV4cCI6MTk4NTcwNzk0N30.YmIOoFGYxIBGfG1ICihRzHkfWXfLH2L49Tk_Sd9vqmc'

// const supabaseUrl = "https://rxvqvtpbsuugyzcrwopz.supabase.co";
// const supabaseKey =
//   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InJ4dnF2dHBic3V1Z3l6Y3J3b3B6Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTY0NDgzMjMsImV4cCI6MjAzMjAyNDMyM30.FdCdVeBG97BtnMS3eTW1fvMp8sUx9y3DOocV2Y2xUmE";

const supabaseUrl = "https://bpujqycnfuosyxuyfpda.supabase.co";
const supabaseKey =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImJwdWpxeWNuZnVvc3l4dXlmcGRhIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDk1MzY5ODksImV4cCI6MjAyNTExMjk4OX0.G75nFo3vFJN7zgjzJYrq75x369gBoSkb54XJuiLBC1k";

export const supabase = createClient(supabaseUrl, supabaseKey);
