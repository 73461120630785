import React, { useState, useRef, useContext, useEffect } from "react";
import Record from "../../components/Record/Record";
import "./AddPage.css";
import Joyride from "react-joyride";

import { CurrentDataContext } from "../../App";

import { Link, useNavigate } from "react-router-dom";
import { supabase } from "../../client";

import EnDict from "../../assets/dict/EnDict.json";
import CnDict from "../../assets/dict/CnDict.json";
import ArDict from "../../assets/dict/ArDict.json";

import Switch from "react-switch";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import Modal from "react-modal";
import ReactLoading from "react-loading";

import { useLocalStorage } from "../../hooks/useLocalStorage";

const API_HOST_URL = "https://api.letbabytalk.com/process";
// const API_HOST_URL = "http://10.127.54.11:8000/process";

const AddPage = ({ token }) => {
  const { data, setData } = useContext(CurrentDataContext);

  console.log("data: ", data);

  const userId = token.user.id;
  const [emptyMedia, setEmptyMedia] = useState(true);
  const [open, setOpen] = useState("");
  const mediaFile = useRef();
  const [isDisable, setIsDisable] = useState(true);
  const myRef = useRef(null);

  const baby = JSON.parse(sessionStorage.getItem("baby"));

  console.log(token);
  const navigate = useNavigate();

  const [dict, setDict] = useState();

  const [runTour, setRunTour] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);

  const [showPrediction, setShowPrediction] = useState(
    data?.showPrediction || false
  );

  const {
    getItem: getShowPredictionItem,
    setItem: setShowPredictionItem,
    removeItem: removeShowPredictionItem,
  } = useLocalStorage("showPrediction");

  const handleChange = (nextChecked) => {
    setShowPrediction(nextChecked);
    setShowPredictionItem(nextChecked);
  };

  const steps = [
    {
      target: ".record__wrapper",
      content: dict?.guide_record,
      placement: "bottom",
      disableBeacon: true,
    },
    {
      target: ".switch-section",
      content: dict?.guide_show_prediction,
      placement: "bottom",
      disableBeacon: true,
    },
  ];

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "transparent",
      border: "0px solid transparent",
    },
  };

  useEffect(() => {
    if (data.guide == true) {
      console.log("run");
      setRunTour(true);
      setData((prev) => ({
        ...prev,
        guide: false,
      }));
    }
  }, [data.guide]);

  function formatDateToTimestamp(date) {
    // Get the date components
    var year = date.getFullYear().toString();
    var month = (date.getMonth() + 1).toString().padStart(2, "0");
    var day = date
      .getDate()
      .toString()
      .padStart(2, "0");
    var hours = date
      .getHours()
      .toString()
      .padStart(2, "0");
    var minutes = date
      .getMinutes()
      .toString()
      .padStart(2, "0");
    var seconds = date
      .getSeconds()
      .toString()
      .padStart(2, "0");

    // Concatenate the components without symbols
    var timestamp = year + month + day + hours + minutes + seconds;

    return timestamp;
  }

  useEffect(() => {
    console.log("lang", data.language);
    // setLanguage(data.language);
    switch (data.language) {
      case "en":
        setDict(EnDict);
        break;
      case "zh":
        setDict(CnDict);
        break;
      case "ar":
        setDict(ArDict);
        break;
      default:
        setDict(EnDict);
    }
  }, [data.language]);

  const uploadRecordings = async (file) => {
    // console.log("saved", sessionStorage.getItem("token"));
    // const {
    //   data: { session },
    //   error,
    // } = await supabase.auth.getSession();
    // console.log("data_session", session, session.expires_at);
    // if (!session || !session.expires_at) {
    //   console.log("no session found"); // If there's no session or expiration time, consider it expired
    // }

    // const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
    // console.log("currenttime", currentTime);
    // if (session.expires_at <= currentTime) {
    //   console.log("session expired");
    // }

    try {
      //   setLoading(true);
      const filename = `${userId}_${formatDateToTimestamp(new Date())}.wav`;
      const { resp, error } = await supabase.storage
        .from("letbabytalk")
        .upload(`${userId}/${filename}`, file);
      // .upload(`recordings/${filename}`, file);
      if (error) {
        throw error;
      }
      return filename;
    } catch (error) {
      console.error("Error adding item", error);
    } finally {
      //   setLoading(false);
    }
  };

  function calculateWeeks(dob) {
    // Convert DOB to milliseconds
    var dobMillis = new Date(dob).getTime();

    // Calculate current date in milliseconds
    var currentDateMillis = Date.now();

    // Calculate the difference in milliseconds
    var differenceMillis = currentDateMillis - dobMillis;

    // Convert milliseconds to weeks
    var weeks = Math.floor(differenceMillis / (1000 * 60 * 60 * 24 * 7));

    return weeks;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (mediaFile.current == null || mediaFile.current.size <= 0) {
      setEmptyMedia(true);
      return;
    }
    setOpen(true);

    // const filename = await uploadRecordings(mediaFile.current);
    // console.log(filename);

    let formData = new FormData();
    formData.append("audio", mediaFile.current);
    formData.append("id", userId);

    Promise.all([
      uploadRecordings(mediaFile.current),
      fetch(API_HOST_URL, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      }),
    ])
      .then(async (results) => {
        // Marking function as async to use await inside
        console.log("results", results);
        const filename = results[0];
        const response = results[1];

        try {
          const resu = await response.json(); // Ensure json processing completes
          console.log("/process result: ", resu);
          if (resu.status == "404 cry_not_found") {
            setOpen(false);
            alert(dict?.error_no_cry_detected);
          } else if (resu.status == "200 ok") {
            if (
              resu &&
              resu.data.probs &&
              resu.data.probs.length > 0 &&
              resu.data.probs[0].length > 0
            ) {
              // setLoading(true); Uncomment this if needed

              // Await keyword can be used as the function is now async
              const { data, error } = await supabase
                .from("Record")
                .insert([
                  {
                    user_id: userId,
                    baby_id: baby.id,
                    //check here later
                    prediction: resu.data.probs[0],
                    reason: "",
                    correct: "",
                    location: "",
                    recordings: filename,
                    value: resu.data.value,
                    age: calculateWeeks(baby.dob),
                    created_at: new Date(),
                    updated_at: new Date(),
                  },
                ])
                .select();

              if (error) {
                throw error;
              }

              setData((prev) => ({
                ...prev,
                record: data[0],
                result: resu,
                showPrediction: showPrediction,
              }));
              setOpen(false);
              navigate("/result");
            } else {
              alert("Unknown error. Please contact lettingbabytalk@gmail.com");
            }
          }
        } catch (error) {
          console.error("Error adding item", error);
          alert("Error, please logout and try again.");
        } finally {
          // setLoading(false); Uncomment this if needed
          setOpen(false);
        }
      })
      .catch((error) => {
        console.log("error occurred", error);
      });
  };

  // const response = await fetch(API_HOST_URL, {
  //   method: "POST",
  //   body: formData,
  //   headers: {
  //     // "content-type": "multipart/form-data",
  //     // "Content-Type": "application/json",
  //   },
  // });
  //   const resu = await response.json();

  //   if (
  //     resu &&
  //     resu.data.probs &&
  //     resu.data.probs.length > 0 &&
  //     resu.data.probs[0].length > 0
  //   ) {
  //     try {
  //       //   setLoading(true);
  //       const { data, error } = await supabase
  //         .from("Record")
  //         .insert([
  //           {
  //             user_id: userId,
  //             baby_id: baby.id,
  //             //check here later
  //             prediction: resu.data.probs[0],
  //             reason: "",
  //             correct: "",
  //             location: "",
  //             recordings: filename,
  //             value: resu.data.value,
  //             age: calculateWeeks(baby.dob),
  //             created_at: new Date(),
  //             updated_at: new Date(),
  //           },
  //         ])
  //         .select();
  //       if (error) {
  //         throw error;
  //       }
  //       setData((prev) => ({
  //         ...prev,
  //         record: data[0],
  //         result: resu,
  //       }));
  //       setOpen(false);
  //       navigate("/result");
  //     } catch (error) {
  //       console.error("Error adding item", error);
  //     } finally {
  //       //   setLoading(false);
  //     }
  //   }
  // };

  const handleJoyrideCallback = (data) => {
    const { action, index, type } = data;
    console.log("act", action, index, type);

    if (type === "step:after" || type === "tooltip:next") {
      setStepIndex(index + (action === "prev" ? -1 : 1));
    }

    if (type === "tour:end") {
      setRunTour(false);
      setStepIndex(0);
    }
  };

  return (
    <div className="addpage__wrapper">
      <Joyride
        steps={steps}
        run={runTour}
        stepIndex={stepIndex}
        continuous
        showSkipButton
        callback={handleJoyrideCallback}
        locale={{
          back: dict?.back_step,
          close: dict?.close,
          last: dict?.last,
          next: dict?.next,
          open: dict?.open_dialog,
          skip: dict?.skip,
        }}
        styles={{
          options: {
            zIndex: 10000,
          },
        }}
      />
      <Modal
        isOpen={open}
        onClose={() => setOpen(false)}
        center
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
        showCloseIcon={false}
        style={customStyles}
        container={myRef.current}
      >
        <ReactLoading
          type={"cubes"}
          color={"#92F2F2"}
          height={200}
          width={200}
        />
      </Modal>
      {/* <div className="back__wrapper">
        <FontAwesomeIcon
          className="input__icon"
          color="black"
          icon={faArrowLeft}
          onClick={() => navigate("/homepage")}
          size="2x"
        />
      </div> */}
      <form className="addpage__form-wrapper">
        <Record
          setEmptyMedia={setEmptyMedia}
          mediaFile={mediaFile}
          currentId={userId}
          setOpen={setOpen}
          setIsDisable={setIsDisable}
        ></Record>
        {emptyMedia && (
          <div className="invalid-feedback">{dict?.error_please_record}</div>
        )}
        <div className="switch-section">
          {dict?.show_prediction_result}
          <Switch
            onChange={handleChange}
            checked={showPrediction}
            className="react-switch"
          />
        </div>

        <div className="editpage__button-wrapper">
          <button
            type="button"
            className="addpage__button"
            onClick={handleSubmit}
            disabled={isDisable}
          >
            {dict?.submit}
          </button>
          <button
            className="editpage__button-red"
            type="button"
            onClick={() => {
              navigate("/homepage");
            }}
          >
            {/* <FontAwesomeIcon color="black" icon={faHouse} size="1x" />{" "} */}
            <span>{dict?.back}</span>
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddPage;
