import React, { useState, useRef, useEffect, useContext } from "react";
import "./Record.css";
import recordBgImage from "../../assets/img/recordBg.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { CurrentDataContext } from "../../App";

import EnDict from "../../assets/dict/EnDict.json";
import CnDict from "../../assets/dict/CnDict.json";
import ArDict from "../../assets/dict/ArDict.json";
import Loading from "../Loading/Loading";

import { MediaRecorder, register } from "extendable-media-recorder";
import { connect } from "extendable-media-recorder-wav-encoder";

const maxRecTime = 30;

const TYPE_LIST = ["Pain", "Hungry", "Asphyxia", "Deaf"];
const API_HOST_URL = "http://192.168.0.100:8000/process";

const Record = ({
  currentId,
  mediaFile,
  setEmptyMedia,
  setOpen,
  setIsDisable,
}) => {
  const { data, setData } = useContext(CurrentDataContext);
  const [status, setStatus] = useState("start");

  const [result, setResult] = useState();
  const [timer, setTimer] = useState(0);
  const [dict, setDict] = useState();

  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const audioRef = useRef(null); // Ref for the audio element
  const canvasRef = useRef(null); // Ref for the canvas element
  const [recording, setRecording] = useState(null);

  const [permissionStatus, setPermissionStatus] = useState("granted");
  const [isMicInitialized, setIsMicInitialized] = useState(false); // New state to track if the mic is initialized

  const STATUS_TEXT = {
    start: dict?.start_a_new_recording,
    recording: dict?.recording,
    stop: dict?.recording_stop,
    end: "Analyzed results",
  };

  useEffect(() => {
    console.log("lang", data.language);
    switch (data.language) {
      case "en":
        setDict(EnDict);
        break;
      case "zh":
        setDict(CnDict);
        break;
      case "ar":
        setDict(ArDict);
        break;
      default:
        setDict(EnDict);
    }
  }, [data.language]);

  useEffect(() => {
    let intervalId;

    if (status === "recording") {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer + 1);
        if (timer >= maxRecTime) {
          clearInterval(intervalId); // Stop recording after maxRecTime seconds
          setStatus("stop");
          stopRecording();
        }
      }, 1000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [status, timer]);

  const initializeEncoder = async () => {
    try {
      await register(await connect());
    } catch (error) {
      if (error.message.includes("encoder stored")) {
        console.log("Encoder already registered.");
      } else {
        console.error("Error registering encoder:", error);
      }
    }
  };

  useEffect(() => {
    initializeEncoder();
  }, []);

  const startRecording = async (stream) => {
    setEmptyMedia(false);
    setIsDisable(true);
    const mediaRecorder = new MediaRecorder(stream, { mimeType: "audio/wav" });
    mediaRecorderRef.current = mediaRecorder;
    audioChunksRef.current = [];

    mediaRecorder.ondataavailable = (event) => {
      audioChunksRef.current.push(event.data);
    };

    mediaRecorder.onstop = () => {
      const audioBlob = new Blob(audioChunksRef.current, {
        type: "audio/wav",
      });

      const audioUrl = URL.createObjectURL(audioBlob);
      setRecording(audioUrl);
      mediaFile.current = new File([audioBlob], "recording.wav", {
        type: "audio/wav",
      });
      if (audioRef.current) {
        audioRef.current.load(); // Load the audio element to refresh the source
      }
      console.log("end?", stream);
      stream.getTracks().forEach((track) => track.stop()); // Stop the stream
    };

    const audioContext = new (window.AudioContext ||
      window.webkitAudioContext)();
    const source = audioContext.createMediaStreamSource(stream);
    const analyser = audioContext.createAnalyser();
    analyser.fftSize = 256;
    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    source.connect(analyser);

    const draw = () => {
      if (!canvasRef.current) {
        return;
      }
      const canvas = canvasRef.current;
      const canvasCtx = canvas.getContext("2d");

      const WIDTH = canvas.width;
      const HEIGHT = canvas.height;

      canvasCtx.clearRect(0, 0, WIDTH, HEIGHT);

      requestAnimationFrame(draw);

      analyser.getByteFrequencyData(dataArray);

      canvasCtx.fillStyle = "white";
      canvasCtx.fillRect(0, 0, WIDTH, HEIGHT);

      canvasCtx.lineWidth = 2;
      canvasCtx.strokeStyle = "#FF4500"; // Color of the waveform

      const barWidth = (WIDTH / bufferLength) * 1;
      let x = 0;

      for (let i = 0; i < bufferLength; i++) {
        const barHeight = dataArray[i] / 2 + 1;

        // canvasCtx.fillStyle = "rgba(56, 197, 197, 1)"; // Color of the bars
        // canvasCtx.fillStyle = "rgba(255, 69, 58, 1)"; // Color of the bars
        canvasCtx.fillStyle = "black"; // Color of the bars

        canvasCtx.fillRect(x, HEIGHT / 2 - barHeight / 2, barWidth, barHeight);

        x += barWidth + 1;
      }
    };

    mediaRecorder.start();
    setTimer(0);
    setStatus("recording");
    requestAnimationFrame(draw); // Start the drawing loop
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && status === "recording") {
      mediaRecorderRef.current.stop();
      setIsDisable(false);
      console.log("stoppppp");
      setStatus("stop");
    }
  };

  useEffect(() => {
    navigator.permissions
      .query({ name: "microphone" })
      .then((permissionStatus) => {
        console.log("mic permission: ", permissionStatus);
        setPermissionStatus(permissionStatus.state);
      });
  }, []);

  // const handleRecordButtonClick = () => {
  //   if (status === "start") {
  //     try {
  //       navigator.mediaDevices
  //         .getUserMedia({ audio: true })
  //         .then(startRecording);
  //     } catch (error) {
  //       console.log("Error accessing microphone: ", error);
  //     }
  //   } else if (status === "recording") {
  //     stopRecording();
  //   }
  // };
  const handleRecordButtonClick = () => {
    if (status === "start") {
      if (permissionStatus === "granted") {
        navigator.mediaDevices
          .getUserMedia({ audio: true })
          .then(startRecording);
      } else {
        navigator.mediaDevices
          .getUserMedia({ audio: true })
          .then((stream) => {
            setPermissionStatus("granted");
            startRecording(stream);
          })
          .catch((error) => {
            setPermissionStatus("denied");
            console.log("Error accessing microphone: ", error);
          });
      }
    } else if (status === "recording") {
      stopRecording();
    }
  };

  return (
    <div className="record__wrapper">
      <>
        <div
          className="ready"
          onClick={() => {
            handleRecordButtonClick();
          }}
        >
          {status !== "processing" && (
            <div className="title">{STATUS_TEXT[status]} </div>
          )}
          {status === "recording" && (
            <div style={{ color: "gray" }}>
              {timer} / {maxRecTime} {dict?.sec}
            </div>
          )}

          <div className="canvas-container">
            <canvas
              ref={canvasRef}
              width="300"
              height={status === "recording" ? "100" : "0"}
            ></canvas>
          </div>

          {["start", "recording"].includes(status) && (
            <div
              className="record"
              style={{
                backgroundImage: `url(${recordBgImage})`,
              }}
            >
              {status === "start" && <div className="start"></div>}
              {status === "recording" && <div className="recording"></div>}
            </div>
          )}

          {status === "start" && (
            <div className="title">{dict?.tap_to_record}</div>
          )}
          {status === "recording" && (
            <div className="title">{dict?.tap_to_stop}</div>
          )}

          {status === "stop" && recording && (
            <div className="stop">
              <audio id="audio" ref={audioRef} src={recording} controls>
                Audio type not supported
              </audio>
              <div className="record__button">
                <div
                  className="cancel"
                  onClick={() => {
                    setStatus("start");
                    mediaFile.current = null;
                    setIsDisable(true);
                  }}
                >
                  <FontAwesomeIcon color="black" icon={faTrash} size="1x" />
                  {dict?.delete}
                </div>
              </div>
            </div>
          )}
        </div>
      </>
      <style>{`
        .record__wrapper {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
        .canvas-container {
          position: relative;
          margin-bottom: 10px;
        }
        .record {
          position: relative;
          background-image: url(${recordBgImage});
          width: 100px;
          height: 100px;
        }
      `}</style>
    </div>
  );
};

export default Record;
