import { useCallback, useEffect, useState } from "react";
import "./PercentItem.css";

import { faPlusCircle, faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useCollapse } from "react-collapsed";

export default function PercentItem(props) {
  const { name, percent, isResult, explain } = props;
  const [value, setValue] = useState(0);
  const [showExplain, setShowExplain] = useState(false);

  const [isExpanded, setExpanded] = useState(false);
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });

  const upDateNumber = useCallback(() => {
    const target = percent;
    const d = Number(value);
    const increment = target / 200;

    if (d < target) {
      let result = Math.ceil(d + increment);
      setValue(result);
    } else {
      setValue(target);
    }
  }, [value]);

  useEffect(() => {
    setTimeout(upDateNumber, 10);
  }, [value]);

  return (
    // <div className="result-item">
    <div className={isResult ? "result-item-result" : "result-item"}>
      <div
        className="name"
        onMouseEnter={() => {
          // setShowExplain(true);
          setExpanded(true);
        }}
        onMouseLeave={() => {
          // setShowExplain(false);
          setExpanded(false);
        }}
      >
        {isExpanded ? (
          <FontAwesomeIcon
            icon={faMinusCircle}
            style={{ marginRight: "0.5rem" }}
          />
        ) : (
          <FontAwesomeIcon
            icon={faPlusCircle}
            style={{ marginRight: "0.5rem" }}
          />
        )}
        {name} : {value > 0 ? value.toFixed(0) : 0}%{" "}
        <section className="percent__explain" {...getCollapseProps()}>
          {explain}
        </section>
        {/* {showExplain && <div className="percent__explain">{explain}</div>} */}
        <div className="percent">
          <div className="value" style={{ width: `${value}%` }}></div>
        </div>
      </div>
    </div>
  );
}
