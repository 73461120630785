import React, { useRef, useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { supabase } from "../../client";
import {
  faEnvelope,
  faPhone,
  faLock,
  faEyeSlash,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import BoxInput from "../../components/BoxInput/BoxInput";
import "./SignInPage.css";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import zh from "react-phone-number-input/locale/zh";
import en from "react-phone-number-input/locale/en";
import EnDict from "../../assets/dict/EnDict.json";
import CnDict from "../../assets/dict/CnDict.json";
import ArDict from "../../assets/dict/ArDict.json";
import { CurrentDataContext } from "../../App";

const SignInPage = ({ setToken }) => {
  let navigate = useNavigate();

  const { data, setData } = useContext(CurrentDataContext);

  const [signUpType, setSignUpType] = useState("email");
  const [visible, setVisible] = useState(false);
  const handleRightIconClick = () => {
    setVisible(!visible);
  };

  const emailRef = useRef();
  const [emptyEmail, setEmptyEmail] = useState();
  const [errorEmail, setErrorEmail] = useState();
  const passwordRef = useRef();
  const [emptyPassword, setEmptyPassword] = useState();
  const phoneRef = useRef();
  const [emptyPhone, setEmptyPhone] = useState();
  // const [errorEmail, setErrorEmail] = useState();

  const [phone, setPhone] = useState();

  const [dict, setDict] = useState();

  useEffect(() => {
    console.log("lang", data.language);
    // setLanguage(data.language);
    switch (data.language) {
      case "en":
        setDict(EnDict);
        break;
      case "zh":
        setDict(CnDict);
        break;
      case "ar":
        setDict(ArDict);
        break;
      default:
        setDict(EnDict);
    }
  }, [data.language]);

  const [formData, setFormData] = useState({
    phone: "",
    email: "",
    password: "",
  });

  function handleChange(event) {
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [event.target.name]: event.target.value,
      };
    });
  }

  async function handleSubmit(e) {
    e.preventDefault();

    if (signUpType === "email") {
      if (formData.email === "") {
        setEmptyEmail(true);
        // emailRef.current.focus();
        return;
      }
      let emailRegex = /[a-z0-9]+@\w+\.[a-z]{2,3}/;
      if (!emailRegex.test(formData.email)) {
        // invalid email
        setErrorEmail(true);
        // emailRef.current.focus();
        return;
      }
      if (formData.password === "") {
        setEmptyPassword(true);
        // passwordRef.current.focus();
        return;
      }
      // return;
      try {
        const { data: resp, error } = await supabase.auth.signInWithPassword({
          email: formData.email,
          password: formData.password,
        });

        if (error) throw error;
        setToken(resp);
        setData((prev) => ({
          ...prev,
          token: resp.session.access_token,
        }));
        navigate("/homepage");

        //   alert('Check your email for verification link')
      } catch (error) {
        alert(error);
      }
    } else {
      formData.phone = phone;
      let phoneRegex = /^\+\d{6}/;
      if (formData.phone === "" || !phoneRegex.test(formData.phone)) {
        setEmptyPhone(true);
        // emailRef.current.focus();
        return;
      }
      if (formData.password === "") {
        setEmptyPassword(true);
        // passwordRef.current.focus();
        return;
      }
      // return;
      try {
        const { data: resp, error } = await supabase.auth.signInWithPassword({
          phone: formData.phone,
          password: formData.password,
        });

        if (error) throw error;
        setToken(resp);
        setData((prev) => ({
          ...prev,
          token: resp.session.access_token,
        }));
        navigate("/homepage");

        //   alert('Check your email for verification link')
      } catch (error) {
        alert(error);
      }
    }
  }
  return (
    <div className="login__wrapper">
      <h1 style={{ margin: "0rem" }}>{dict?.welcome_to_leybabytalk}</h1>
      <h2 style={{ margin: "0rem" }}>{dict?.understand_every_baby_cries}</h2>
      <h1>{dict?.login_to_existing_account}</h1>
      <div className="login__button-side">
        <button
          onClick={() => {
            setSignUpType("email");
          }}
        >
          {dict?.email}
        </button>
        <button
          onClick={() => {
            setSignUpType("phone");
          }}
        >
          {dict?.phone}
        </button>
      </div>
      <form className="login__form-wrapper" onSubmit={handleSubmit}>
        {signUpType === "phone" && (
          <p className="app__alert-red">{dict?.sms_advice}</p>
        )}
        {signUpType === "email" && (
          <BoxInput
            leftIcon={faEnvelope}
            name={"email"}
            placeholder={dict?.enter_your_email}
            type={"text"}
            handleChange={handleChange}
            ref={emailRef}
            error={emptyEmail ? emptyEmail : errorEmail}
            errorText={
              emptyEmail ? dict?.error_enter_email : dict?.error_invalid_email
            }
            setError={emptyEmail ? setEmptyEmail : setErrorEmail}
          ></BoxInput>
        )}
        {signUpType === "phone" && (
          <BoxInput
            leftIcon={faPhone}
            name={"phone"}
            type="text"
            placeholder={dict?.enter_your_phone}
            handleChange={handleChange}
            phone={phone}
            setPhone={setPhone}
            ref={phoneRef}
            error={emptyPhone}
            errorText={dict?.error_fill_in_valid_phone_number}
            setError={setEmptyPhone}
          ></BoxInput>
        )}
        <BoxInput
          leftIcon={faLock}
          rightIcon={visible ? faEye : faEyeSlash}
          name={"password"}
          type={visible ? "text" : "password"}
          placeholder={dict?.enter_your_password}
          handleChange={handleChange}
          handleRightIconClick={handleRightIconClick}
          ref={passwordRef}
          error={emptyPassword}
          errorText={dict?.error_fill_in_password}
          setError={setEmptyPassword}
        ></BoxInput>
        <div className="login__wrapper-left">
          <p>
            <Link to={"/forget"}>{dict?.forget_password}</Link>
          </p>
        </div>
        <button className="login__button-wrapper" type="submit">
          {dict?.sign_in}
        </button>
      </form>
      <div style={{ margin: "1rem" }}>
        {dict?.havent_register} <Link to="/signup">{dict?.sign_up}</Link>{" "}
        {dict?.here}
      </div>
      <hr
        style={{ width: "100%", marginTop: "1rem", marginBottom: "1rem" }}
      ></hr>
      <div className="skip__wrapper">
        <div className="skip__wrapper-col">
          <h1 style={{ margin: "0rem" }}>{dict?.dont_want_to_signup}</h1>
          &nbsp;
          <button
            className="skip__button"
            onClick={() => {
              setData((prev) => ({
                ...prev,
                newUser: true,
              }));

              navigate("/ghomepage");
            }}
          >
            {dict?.record_now}&nbsp;
            <p className="invalid-feedback ">{dict?.records_wont_save}</p>
          </button>
        </div>
      </div>
      <div
        style={{
          margin: "0px",
          padding: "0px",
          color: "black",
          opacity: "0.5",
        }}
      ></div>
    </div>
  );
};

export default SignInPage;
