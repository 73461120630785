import React, { useState, useEffect, useContext, useRef } from "react";
import "./BabyPage.css";
import { useNavigate } from "react-router-dom";
import { supabase } from "../../client";
import { CurrentDataContext } from "../../App";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faSquareXmark,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import Modal from "react-modal";

import EnDict from "../../assets/dict/EnDict.json";
import CnDict from "../../assets/dict/CnDict.json";
import ArDict from "../../assets/dict/ArDict.json";

const BabyPage = ({ token, change, setChange }) => {
  const userId = token.user.id;
  const { data, setData } = useContext(CurrentDataContext);
  const status = data.status;
  console.log("babypage", data);
  const myRef = useRef(null);

  const [open, setOpen] = useState(false);

  const [name, setName] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState(); // ISO format for date input
  const [gender, setGender] = useState("");
  const [loading, setLoading] = useState();
  const navigate = useNavigate();
  const [dict, setDict] = useState();

  useEffect(() => {
    console.log("lang", data.language);
    // setLanguage(data.language);
    switch (data.language) {
      case "en":
        setDict(EnDict);
        break;
      case "zh":
        setDict(CnDict);
        break;
      case "ar":
        setDict(ArDict);
        break;
      default:
        setDict(EnDict);
    }
  }, [data.language]);

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleDateChange = (event) => {
    setDateOfBirth(event.target.value);
  };

  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };

  useEffect(() => {
    if (status === "edit") {
      setName(data?.baby.name);
      setDateOfBirth(data?.baby.dob);
      setGender(data?.baby.gender);
    }
  }, []);

  const addBaby = async (userId, name, dob, gender) => {
    try {
      setLoading(true);
      const { data: resp, error } = await supabase.from("Baby").insert([
        {
          user_id: userId,
          name,
          dob,
          gender,
          created_at: new Date(),
          updated_at: new Date(),
        },
      ]);

      if (error) {
        throw error;
      }
      return resp;
    } catch (error) {
      console.error("Error adding item", error);
    } finally {
      setLoading(false);
    }
  };

  const editBaby = async (name, dob, gender) => {
    try {
      setLoading(true);
      const { data: resp, error } = await supabase
        .from("Baby")
        .update({
          name,
          dob,
          gender,
          updated_at: new Date(),
        })
        .eq("id", data.baby.id)
        .select();

      if (error) {
        throw error;
      }
      return resp;
    } catch (error) {
      console.error("Error adding item", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle the confirm action (e.g., form submission)
    console.log("Profile Confirmed with:", { name, dateOfBirth, gender });
    if (status === "add") {
      addBaby(userId, name, dateOfBirth, gender);
    } else {
      editBaby(name, dateOfBirth, gender);
    }
    setChange(true);
    navigate("/homepage");
  };

  const handleDelete = async () => {
    // Handle the delete action
    const { error } = await supabase
      .from("Baby")
      .delete()
      .eq("id", data.baby.id);
  };

  const handleAction = () => {
    console.log(".....", status);
    if (status === "add") {
      navigate("/homepage");
    } else {
      setOpen(true);
    }
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "auto",
    },
    overlay: {
      // backgroundColor: "transparent",
    },
  };

  return (
    <div className="babypage__wrapper">
      <Modal
        isOpen={open}
        onClose={() => setOpen(false)}
        center
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        showCloseIcon={true}
        style={customStyles}
        container={myRef.current}
      >
        <div
          className={`customModal__button-wrapper ${
            data.language == "ar" ? "arabic" : ""
          }`}
        >
          <p>{dict?.are_you_sure_delete}</p>
          <button
            className="createpage__button"
            onClick={() => {
              setOpen(false);
            }}
          >
            <FontAwesomeIcon color="black" icon={faSquareXmark} size="2x" />{" "}
            <span>{dict?.cancel}</span>
          </button>
          <button
            className="createpage__button"
            onClick={(rec) => {
              setOpen(false);
              handleDelete();
              alert("deleted");
              setChange(true);
              navigate("/homepage");
            }}
          >
            <FontAwesomeIcon color="black" icon={faTrash} size="2x" />
            <span>{dict?.delete}</span>
          </button>
        </div>
      </Modal>
      <div className="back__wrapper">
        <FontAwesomeIcon
          className="input__icon"
          color="black"
          icon={faArrowLeft}
          onClick={() => navigate("/homepage")}
          size="2x"
        />
      </div>
      <div className="space"></div>
      <div className="text-overflow">
        <h1>{dict?.edit_child_profile}</h1>
      </div>
      <form
        className="babypage__form"
        onSubmit={(event) => handleSubmit(event)}
      >
        <div className="input-group">
          <label>{dict?.name}</label>
          <input
            type="text"
            value={name}
            placeholder={dict?.name}
            onChange={handleNameChange}
          />
        </div>
        <div className="input-group">
          <label>{dict?.dob}</label>
          <input type="date" value={dateOfBirth} onChange={handleDateChange} />
        </div>
        <div className="input-group">
          <label>{dict?.gender}</label>
          <select value={gender} onChange={handleGenderChange}>
            <option value="">{dict?.please_select}</option>
            <option value="male">{dict?.male}</option>
            <option value="female">{dict?.female}</option>
          </select>
        </div>
        <div className="babypage__button-wrapper">
          <button className="babypage__button">{dict?.add}</button>
          <button
            type="button"
            className="babypage__button-red"
            onClick={() => handleAction()}
          >
            {status === "add" ? dict?.cancel : dict?.delete}
          </button>
        </div>
      </form>
    </div>
  );
};

export default BabyPage;
