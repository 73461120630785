import React, { useRef, useState, useEffect, useContext } from "react";
import EnDict from "../../assets/dict/EnDict.json";
import CnDict from "../../assets/dict/CnDict.json";
import ArDict from "../../assets/dict/ArDict.json";
import { CurrentDataContext } from "../../App";
import { useLocation } from "react-router-dom";

const TermsConditions = ({ setTncModalOpen }) => {
  const { data, setData } = useContext(CurrentDataContext);
  const [dict, setDict] = useState();
  // const location = useLocation();
  // const queryParams = new URLSearchParams(location.search);
  // const language = queryParams.get("lang") || "en";

  // useEffect(() => {
  //   setData((prev) => ({
  //     ...prev,
  //     language: language,
  //   }));
  // }, []);

  useEffect(() => {
    console.log("lang", data.language);
    // setLanguage(data.language);
    switch (data.language) {
      case "en":
        setDict(EnDict);
        break;
      case "zh":
        setDict(CnDict);
        break;
      case "ar":
        setDict(ArDict);
        break;
      default:
        setDict(EnDict);
    }
  }, [data.language]);

  function handleClose() {
    // window.open("about:blank", "_self");
    // window.close();
    setTncModalOpen(false);
  }
  return (
    <div className={`${data.language == "ar" ? "arabic" : ""}`}>
      <h1>{dict?.terms_and_conditions}</h1>
      <h2>{dict?.welcome_to_letbabytalk}</h2>
      <p>{dict?.tnc0}</p>

      <h3>{dict?.tnc1}</h3>
      <p>{dict?.tnc1c}</p>

      <h3>{dict?.tnc2}</h3>
      <p>{dict?.tnc2c}</p>

      <h3>{dict?.tnc3}</h3>
      <p>{dict?.tnc3c}</p>

      <h3>{dict?.tnc4}</h3>
      <p>{dict?.tnc4c}</p>

      <h3>{dict?.tnc5}</h3>
      <p>{dict?.tnc5c}</p>
      <button className="login__button-wrapper" onClick={() => handleClose()}>
        {dict?.agree}
      </button>
    </div>
  );
};

export default TermsConditions;
