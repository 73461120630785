import React, { useEffect, useState, useRef, useContext } from "react";
import { generateClient } from "aws-amplify/api";
import { CurrentDataContext } from "../../App";
import "./GuestHomePage.css";
import { v4 as uuid } from "uuid";
import { Link, useNavigate } from "react-router-dom";
import Record from "../../components/Record/Record";
import { uploadData } from "aws-amplify/storage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Joyride from "react-joyride";
import TermsConditions from "../TermsConditions/TermsConditions";
import PrivacyPolicy from "../PrivacyPolicy/PrivacyPolicy";

import {
  faUserPlus,
  faPaperPlane,
  faArrowLeft,
  faCircleCheck,
} from "@fortawesome/free-solid-svg-icons";
import Modal from "react-modal";
// import { Modal as RespModal } from "react-responsive-modal";
import ReactLoading from "react-loading";
import EnDict from "../../assets/dict/EnDict.json";
import CnDict from "../../assets/dict/CnDict.json";
import ArDict from "../../assets/dict/ArDict.json";
import { wait } from "@testing-library/user-event/dist/utils";
import { supabase } from "../../client";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import Switch from "react-switch";

const client = generateClient();

// const API_HOST_URL = "http://192.168.8.4:8000/process";
// conast API_HOST_URL = "http://44.201.193.214/process";
// const API_HOST_URL = "http://127.0.0.1:8000/process";
const API_HOST_URL = "https://api.letbabytalk.com/process";
const key = "guest";

const GuestHomePage = ({}) => {
  const { data, setData } = useContext(CurrentDataContext);
  console.log("guesthomepage:", data);
  const { getItem, setItem, removeItem } = useLocalStorage(key);
  const [dict, setDict] = useState();

  const [runTour, setRunTour] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);

  const [tncModalOpen, setTncModalOpen] = useState(false);
  const [ppModalOpen, setPpModalOpen] = useState(false);

  const steps = [
    {
      target: ".navbar__logo-guide",
      content: dict?.guide_guide_guest,
      placement: "bottom",
      disableBeacon: true,
    },
    {
      target: ".record__wrapper",
      content: dict?.guide_record,
      placement: "bottom",
      disableBeacon: true,
    },
    {
      target: ".information__form",
      content: dict?.guide_info,
      placement: "bottom",
      disableBeacon: true,
    },
  ];

  useEffect(() => {
    if (data.guide == true) {
      console.log("run");
      setRunTour(true);
      setData((prev) => ({
        ...prev,
        guide: false,
      }));
    }
  }, [data.guide]);

  useEffect(() => {
    console.log("lang", data.language);
    // setLanguage(data.language);
    switch (data.language) {
      case "en":
        setDict(EnDict);
        break;
      case "zh":
        setDict(CnDict);
        break;
      case "ar":
        setDict(ArDict);
        break;
      default:
        setDict(EnDict);
    }
  }, [data.language]);

  const currentId = uuid();
  const [nickname, setNickname] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const [location, setLocation] = useState("");
  const [locationOther, setLocationOther] = useState("");
  const [selectedBaby, setSelectedBaby] = useState("");
  const [open, setOpen] = useState("");
  const [addOpen, setAddOpen] = useState();
  const [dob, setDob] = useState();

  //   const [nickname, setNickname] = useState("");
  const navigate = useNavigate();
  const [babies, setBabies] = useState();
  const mediaFile = useRef();

  const [accept, setAccept] = useState(false);
  const [errorAccept, setErrorAccept] = useState();

  const [toggleDisplay, setToggleDisplay] = useState(false);

  const [emptyAge, setEmptyAge] = useState(false);
  const [emptyGender, setEmptyGender] = useState(false);
  const [emptyNickname, setEmptyNickname] = useState(false);
  const [emptyLocation, setEmptyLocation] = useState(false);
  const [emptyLocationOther, setEmptyLocationOther] = useState(false);
  const [emptyMedia, setEmptyMedia] = useState(false);
  const [isDisable, setIsDisable] = useState(true);
  const [errorNickname, setErrorNickname] = useState(false);
  const [guest, setGuest] = useState();

  const ageRef = useRef();
  const genderRef = useRef();
  const nicknameRef = useRef();
  const locationRef = useRef();
  const locationOtherRef = useRef();

  const myRef = useRef(null);
  const myRef2 = useRef(null);

  const [permissionStatus, setPermissionStatus] = useState();

  // useEffect(() => {
  //   const fetchGuestToken = async () => {
  //     const pastGuestToken = getItem();
  //     if (pastGuestToken) {
  //       console.log("yes", pastGuestToken);
  //       const resp = await updateGuest(pastGuestToken);
  //       setGuest(resp[0]);
  //     } else {
  //       try {
  //         const resp = await insertGuest();
  //         setGuest(resp[0]);
  //         setItem(resp[0]);
  //       } catch (error) {
  //         console.error("Error fetching or setting guest token:", error);
  //       }
  //     }
  //   };

  //   fetchGuestToken();
  //   console.log("guest", guest);
  // }, []);

  useEffect(() => {
    const fetchAnonymousToken = async () => {
      const { data: resp, error } = await supabase.auth.signInAnonymously();
      if (error) {
        console.log("Error signing in as guest:", error);
        return null;
      }
      return resp;
    };
    fetchAnonymousToken().then((resp) => {
      console.log("see", resp);
      if (resp) {
        setGuest(resp.user);
        setItem(resp.user);
        setData((prev) => ({
          ...prev,
          token: resp.session.access_token,
        }));
      }
    });
  }, []);

  // const waitAndReturnEmptyJSON = () => {
  //   return new Promise((resolve) => {
  //     setTimeout(() => {
  //       resolve({
  //         data: {
  //           audio_id: 2,
  //           value: 0.14,
  //           probs: [
  //             [
  //               0.14677785336971283,
  //               0.2230992168188095,
  //               0.48915785551071167,
  //               0.140965074300766,
  //             ],
  //           ],
  //         },
  //         done: true,
  //         status: "200 ok",
  //       });
  //     }, 2000); // Wait for 2 seconds (2000 milliseconds)
  //   });
  // };

  // const handleSubmit = async (event) => {
  //   event.preventDefault();
  //   const file = mediaFile.current;
  //   const filename = currentId + ".wav";
  //   console.log(filename);

  //   if (true) {
  //     if (nickname == "") {
  //       setEmptyNickname(true);
  //       nicknameRef.current.focus();
  //       return;
  //     }

  //     if (age == "") {
  //       setEmptyAge(true);
  //       ageRef.current.focus();
  //       return;
  //     }

  //     if (gender == "") {
  //       setEmptyGender(true);
  //       genderRef.current.focus();
  //       return;
  //     }

  //     if (location == "") {
  //       setEmptyLocation(true);
  //       locationRef.current.focus();
  //       return;
  //     }

  //     if (location === "others" && locationOther === "") {
  //       setEmptyLocationOther(true);
  //       locationOtherRef.current.focus();
  //       return;
  //     }

  //     if (file == null) {
  //       setEmptyMedia(true);
  //       return;
  //     }
  //   }

  //   if (file) {
  //     await uploadData({
  //       key: filename,
  //       data: file,
  //     });
  //   }

  //   let formData = new FormData();
  //   formData.append("audio", file);

  //   // Display the key/value pairs
  //   for (var pair of formData.entries()) {
  //     console.log(pair[0] + ", " + pair[1]);
  //   }

  //   // function timeout(delay) {
  //   //   return new Promise((res) => setTimeout(res, delay));
  //   // }

  //   try {
  //     setOpen(true);

  //     // await timeout(2000);
  //     // throw "My Error";

  //     // const response = await waitAndReturnEmptyJSON();
  //     // console.log(response);
  //     // const resu = response;

  //     const response = await fetch(API_HOST_URL, {
  //       method: "POST",
  //       body: formData,
  //       headers: {
  //         // "content-type": "multipart/form-data",
  //         // "Content-Type": "application/json",
  //       },
  //     });
  //     const resu = await response.json();

  //     console.log(resu);

  //     if (
  //       resu &&
  //       resu.data.probs &&
  //       resu.data.probs.length > 0 &&
  //       resu.data.probs[0].length > 0
  //     ) {
  //       // let result = resu.probs[0];
  //       let loc = location;
  //       if (loc === "others") {
  //         loc = loc + "/" + locationOther;
  //       }

  //       setData((prev) => ({
  //         ...prev,
  //         id: currentId,
  //         // user: username,
  //         name: nickname,
  //         age: age,
  //         gender: gender,
  //         location: loc,
  //         result: resu,
  //         record: filename,
  //         recordFile: file,
  //       }));

  //       const newRecord = {
  //         id: currentId,
  //         // user: user.username,
  //         name: nickname,
  //         age: age,
  //         gender: gender,
  //         recordings: filename,
  //         location: loc,
  //         prediction: resu.data.probs,
  //         value: resu.data.value,
  //         correct: "",
  //       };

  //       // const resp = await client.graphql({
  //       //   query: guesthomeRecord,
  //       //   variables: { input: newRecord },
  //       // });
  //       // setOpen(false);

  //       navigate("/result");
  //     }
  //     // const response = await API.post();
  //   } catch (error) {
  //     console.error("Error:", error);
  //     setOpen(false);
  //     window.alert("Error. Please retry or contact Admin.");
  //   }
  // };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "transparent",
      border: "0px solid transparent",
    },
  };

  const customStyles2 = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "auto",
    },
    overlay: {
      // backgroundColor: "transparent",
    },
  };

  function formatDateToTimestamp(date) {
    // Get the date components
    var year = date.getFullYear().toString();
    var month = (date.getMonth() + 1).toString().padStart(2, "0");
    var day = date
      .getDate()
      .toString()
      .padStart(2, "0");
    var hours = date
      .getHours()
      .toString()
      .padStart(2, "0");
    var minutes = date
      .getMinutes()
      .toString()
      .padStart(2, "0");
    var seconds = date
      .getSeconds()
      .toString()
      .padStart(2, "0");

    // Concatenate the components without symbols
    var timestamp = year + month + day + hours + minutes + seconds;

    return timestamp;
  }

  const uploadRecordings = async (file) => {
    try {
      //   setLoading(true);
      console.log("guest", guest);
      // const filename = `${guest?.id}_${formatDateToTimestamp(new Date())}.wav`;
      const filename = `${guest?.id}_${formatDateToTimestamp(new Date())}.wav`;
      const { resp, error } = await supabase.storage
        .from("guest")
        .upload(`${guest?.id}/${filename}`, file);
      if (error) {
        throw error;
      }
      return filename;
    } catch (error) {
      console.error("Error adding item", error);
    } finally {
      //   setLoading(false);
    }
  };

  // const updateGuest = async (guestToken) => {
  //   try {
  //     //   setLoading(true);
  //     console.log("guest", guestToken);

  //     const { data: resp, error } = await supabase
  //       .from("Guest")
  //       .update({ updated_at: new Date() })
  //       .eq("user_id", guestToken.user_id)
  //       .select();

  //     if (error) {
  //       throw error;
  //     }
  //     return resp;
  //   } catch (error) {
  //     console.error("Error update item", error);
  //   } finally {
  //     //   setLoading(false);
  //   }
  // };

  // const insertGuest = async () => {
  //   try {
  //     //   setLoading(true);

  //     const { data: resp, error } = await supabase
  //       .from("Guest")
  //       .insert([{ created_at: new Date(), updated_at: new Date() }])
  //       .select();
  //     if (error) {
  //       throw error;
  //     }
  //     return resp;
  //   } catch (error) {
  //     console.error("Error adding item", error);
  //   } finally {
  //     //   setLoading(false);
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("mediafile: ", mediaFile);
    if (mediaFile.current == null || mediaFile.current.size <= 10000) {
      setEmptyMedia(true);
      return;
    }

    if (accept === false) {
      // alert("Please agree terms and conditions");
      setErrorAccept(true);
      return;
    }
    setOpen(true);

    // const filename = await uploadRecordings(mediaFile.current);
    // console.log(filename);

    let formData = new FormData();
    formData.append("audio", mediaFile.current, "recording.wav");
    formData.append("id", guest.id);

    Promise.all([
      uploadRecordings(mediaFile.current),
      fetch(API_HOST_URL, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      }),
    ])
      .then(async (results) => {
        // Marking function as async to use await inside
        console.log("results", results);
        const filename = results[0];
        const response = results[1];

        try {
          const resu = await response.json(); // Ensure json processing completes
          console.log("/process result: ", resu);
          if (resu.status == "404 cry_not_found") {
            setOpen(false);
            alert(dict?.error_no_cry_detected);
          } else if (resu.status == "200 ok") {
            if (
              resu &&
              resu.data.probs &&
              resu.data.probs.length > 0 &&
              resu.data.probs[0].length > 0
            ) {
              // setLoading(true); Uncomment this if needed

              // Await keyword can be used as the function is now async
              const { data: resp, error } = await supabase
                .from("GuestRecord")
                .insert([
                  {
                    user_id: guest.id,
                    baby_id: null,
                    prediction: resu.data.probs[0],
                    reason: "",
                    correct: "",
                    location: "",
                    recordings: filename,
                    value: resu.data.value,
                    dob: dob,
                    created_at: new Date(),
                    updated_at: new Date(),
                    name: nickname,
                    gender: gender,
                  },
                ])
                .select();

              if (error) {
                throw error;
              }

              setData((prev) => ({
                ...prev,
                record: resp[0],
                result: resu,
              }));
              setOpen(false);
              navigate("/gresult");
            } else {
              window.alert(
                "Unknown error. Please contact lettingbabytalk@gmail.com"
              );
            }
          }
        } catch (error) {
          console.error("Error adding item or parsing response", error);
          alert(dict?.error_relogin);
        } finally {
          setOpen(false);

          // setLoading(false); Uncomment this if needed
        }
      })
      .catch((error) => {
        console.log("error occurred", error);
      });
  };

  const handleJoyrideCallback = (data) => {
    const { action, index, type } = data;
    console.log("act", action, index, type);

    if (type === "step:after" || type === "tooltip:next") {
      setStepIndex(index + (action === "prev" ? -1 : 1));
    }

    if (type === "tour:end") {
      setRunTour(false);
      setStepIndex(0);
    }
  };

  useEffect(() => {
    if (data?.newUser == true) {
      setRunTour(true);
      setData((prev) => ({
        ...prev,
        newUser: false,
      }));
    }
  }, []);

  return (
    <div className="guesthomepage__wrapper" onClick={() => setAddOpen(false)}>
      <Joyride
        steps={steps}
        run={runTour}
        stepIndex={stepIndex}
        continuous
        showSkipButton
        callback={handleJoyrideCallback}
        locale={{
          back: dict?.back_step,
          close: dict?.close,
          last: dict?.last,
          next: dict?.next,
          open: dict?.open_dialog,
          skip: dict?.skip,
        }}
        styles={{
          options: {
            zIndex: 10000,
          },
        }}
      />
      {/* <Modal
        isOpen={addOpen}
        onClose={() => setAddOpen(false)}
        center
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        showCloseIcon={true}
        style={customStyles2}
        container={myRef2.current}
      >
        <div className="guesthome__modal-center">
          <FontAwesomeIcon color="black" icon={faCircleCheck} size="2x" />{" "}
          {dict?.added}
        </div>
      </Modal> */}

      <Modal
        isOpen={open}
        onClose={() => setOpen(false)}
        center
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
        showCloseIcon={false}
        style={customStyles}
        container={myRef.current}
      >
        <ReactLoading
          type={"cubes"}
          color={"#92F2F2"}
          height={200}
          width={200}
        />
      </Modal>

      <form
        className="guesthomepage__wrapper-form"
        onSubmit={(event) => handleSubmit(event)}
      >
        {/* <div className="input-group">
          <label>{dict?.profile}</label>
          <select
            name="profile"
            value={selectedBaby}
            onChange={handleBabyChange}
          >
            <option value={""}>{dict?.add_new_baby_profile}</option>
            {babies?.map((baby, index) => (
              <option key={index} value={index}>
                {baby.name}
              </option>
            ))}
          </select>
        </div> */}

        <Record
          setEmptyMedia={setEmptyMedia}
          mediaFile={mediaFile}
          currentId={currentId}
          setOpen={setOpen}
          setIsDisable={setIsDisable}
        ></Record>
        {emptyMedia && (
          <div className="invalid-feedback">{dict?.error_please_record}</div>
        )}
        <h3>
          {dict?.instructions_optional}
          <div className="signup__checkbox">
            <label>
              <input
                className="app__checkbox"
                type="checkbox"
                checked={accept}
                onChange={() => {
                  setAccept(!accept);
                  setErrorAccept(false);
                }}
              />
              {dict?.accept_letbabytalk}
              <span
                onClick={() => setTncModalOpen(true)}
                style={{ color: "blue", cursor: "pointer" }}
              >
                {" "}
                {dict?.termsnregulation}{" "}
              </span>{" "}
              {dict?.and}
              <span
                onClick={() => setPpModalOpen(true)}
                style={{ color: "blue", cursor: "pointer" }}
              >
                {" "}
                {dict?.privacypolicy}{" "}
              </span>
            </label>
            {errorAccept && (
              <div className="invalid-feedback">
                {dict?.error_accept_tnr_pp}
              </div>
            )}
          </div>
          {/* <p style={{ color: "red", margin: "0rem", fontSize: "0.8rem" }}>
            {dict?.by_using_the_apps}
            <Link
              to={`/tnc?lang=${data.language}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              {dict?.termsnregulation}{" "}
            </Link>{" "}
            {dict?.and}
            <Link
              to={`/pp?lang=${data.language}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              {dict?.privacypolicy}{" "}
            </Link>
          </p> */}
        </h3>

        <div className="information__form">
          <label>
            <span>{dict?.optional_fill_in}</span>
            <Switch
              onChange={() => setToggleDisplay(!toggleDisplay)}
              checked={toggleDisplay}
              className="react-switch"
            />
          </label>
        </div>
        {toggleDisplay && (
          <div>
            {" "}
            <div className="input-group">
              <label>{dict?.nickname}</label>
              <input
                type="text"
                name="nickname"
                value={nickname}
                ref={nicknameRef}
                onChange={(e) => {
                  setNickname(e.target.value);
                  setEmptyNickname(false);
                  setErrorNickname(false);
                }}
              />
              {emptyNickname && (
                <div className="invalid-feedback">
                  {dict?.error_please_select}
                </div>
              )}
              {errorNickname && (
                <div className="invalid-feedback">
                  {dict?.error_nickname_already_exists}
                </div>
              )}
            </div>
            <div className="input-group">
              <label>{dict?.dob}</label>
              <input
                type="date"
                value={dob}
                onChange={(e) => {
                  setDob(e.target.value);
                }}
              />
            </div>
            <div className="input-group">
              <label>{dict?.gender}</label>
              <select
                name="gender"
                value={gender}
                ref={genderRef}
                onChange={(e) => {
                  setGender(e.target.value);
                  setEmptyGender(false);
                }}
              >
                <option value="" disabled>
                  {dict?.please_choose}
                </option>
                <option value="male">{dict?.male}</option>
                <option value="female">{dict?.female}</option>
              </select>
              {emptyGender && (
                <div className="invalid-feedback">
                  {dict?.error_please_select}
                </div>
              )}
            </div>
          </div>
        )}

        {/* <div className="input-group">
          <label>{dict?.age}</label>
          <select
            name="age"
            value={age}
            ref={ageRef}
            onChange={(e) => {
              setAge(e.target.value);
              setEmptyAge(false);
            }}
          >
            <option value="" disabled>
              {dict?.please_choose}
            </option>
            <option value="w0_w4">{dict?.w0_w4}</option>
            <option value="w4_w8">{dict?.w4_w8}</option>
            <option value="m2_m6">{dict?.m2_m6}</option>
            <option value="m7_y2">{dict?.m7_y2}</option>
            <option value="y2above">{dict?.y2above}</option>
          </select>{" "}
        </div>
        {emptyAge && (
          <div className="invalid-feedback">{dict?.error_please_select}</div>
        )} */}

        {/* <div className="guesthomepage__add">
          <button
            className="guesthomepage__button"
            type="button"
            onClick={(event) => handleAdd(event)}
          >
            <FontAwesomeIcon color="black" icon={faUserPlus} size="1x" />{" "}
            <span>{dict?.add}</span>
          </button>
        </div> */}

        {/* <div className="input-group">
          <label>{dict?.location}</label>
          <select
            name="location"
            value={location}
            ref={locationRef}
            onChange={(e) => {
              setLocation(e.target.value);
              setEmptyLocation(false);
            }}
          >
            <option value="" disabled>
              {dict?.please_choose}
            </option>
            <option value="daycare_centre">{dict?.location_daycare}</option>
            <option value="home">{dict?.location_home}</option>
            <option value="hospital/clinic">{dict?.location_hc}</option>
            <option value="park/playground">{dict?.location_pp}</option>
            <option value="street">{dict?.location_street}</option>
            <option value="others">{dict?.others}</option>
          </select>{" "}
       
          {emptyLocation && (
            <div className="invalid-feedback">{dict?.error_please_state}</div>
          )}
          {location === "others" && (
            <div className="input-group">
              <label>
                {dict?.please_state}
                <span className="app__label--required">*</span>
              </label>
              <input
                type="text"
                name="otherLocation"
                value={locationOther}
                ref={locationOtherRef}
                onChange={(e) => {
                  setEmptyLocationOther(false);
                  setLocationOther(e.target.value);
                }}
              />
              {emptyLocationOther && (
                <div className="invalid-feedback">
                  {dict?.error_please_state}
                </div>
              )}
            </div>
          )}
        </div> */}

        <div className="ghomepage__button-wrapper">
          <button className="ghomepage__button" disabled={isDisable}>
            {dict?.submit}
          </button>
          <button
            type="button"
            className="babypage__button-red"
            onClick={() => navigate("/signin")}
          >
            {dict?.cancel}
          </button>
        </div>
      </form>
      <Modal
        isOpen={tncModalOpen}
        ariaHideApp={false}
        onRequestClose={() => setTncModalOpen(false)}
        contentLabel="Terms and Conditions"
      >
        <div>
          <TermsConditions setTncModalOpen={setTncModalOpen}></TermsConditions>
        </div>
      </Modal>

      <Modal
        isOpen={ppModalOpen}
        ariaHideApp={false}
        onRequestClose={() => setPpModalOpen(false)}
        contentLabel="Privacy Policy"
      >
        <div>
          {/* Include your privacy policy content here */}
          <PrivacyPolicy setPpModalOpen={setPpModalOpen}></PrivacyPolicy>
        </div>
      </Modal>
    </div>
  );
};

export default GuestHomePage;
