import React, { useContext, useState, useRef, useEffect } from "react";
import "./GuestResPage.css";
import PercentItem from "../../components/PercentItem/PercentItem";
import { CurrentDataContext } from "../../App";
import { useNavigate } from "react-router-dom";
import EnDict from "../../assets/dict/EnDict.json";
import CnDict from "../../assets/dict/CnDict.json";
import ArDict from "../../assets/dict/ArDict.json";
import { supabase } from "../../client";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faSquareXmark,
  faEdit,
  faRotateRight,
} from "@fortawesome/free-solid-svg-icons";
import Switch from "react-switch";
import Joyride from "react-joyride";

const ResPage = () => {
  const { data, setData } = useContext(CurrentDataContext);
  const [dict, setDict] = useState();

  const navigate = useNavigate();
  const [isCorrect, setIsCorrect] = useState("wrong"); // 'correct', 'wrong'
  const [wrongReasons, setWrongReasons] = useState(""); // Array of wrong reasons
  const [feedback, setFeedback] = useState("");
  const [otherReasons, setOtherReasons] = useState("");
  const [location, setLocation] = useState("");
  const [locationOther, setLocationOther] = useState("");
  const [toggleDisplay, setToggleDisplay] = useState(false);

  const myRef = useRef(null);

  const [emptyIsCorrect, setEmptyIsCorrect] = useState(false);
  const [emptyWrongReasons, setEmptyWrongReasons] = useState(false);
  const [emptyOthers, setEmptyOthers] = useState(false);
  const [emptyLocation, setEmptyLocation] = useState(false);
  const [emptyLocationOther, setEmptyLocationOther] = useState(false);

  const [recordUrl, setRecordUrl] = useState();

  const isCorrectRef = useRef();
  const wrongReasonsRef = useRef();
  const othersRef = useRef();
  const locationRef = useRef();
  const locationOtherRef = useRef();

  const [open, setOpen] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [runTour, setRunTour] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);

  const steps = [
    {
      target: ".grespage__wrapper",
      content: dict?.guide_resultpage,
      placement: "bottom",
      disableBeacon: true,
    },
    {
      target: ".gresult__wrapper",
      content: dict?.guide_result,
      placement: "bottom",
      disableBeacon: true,
    },
    {
      target: ".information__form",
      content: dict?.guide_information,
      placement: "bottom",
      disableBeacon: true,
    },
    {
      target: ".grespage__audio",
      content: dict?.guide_audio,
      placement: "bottom",
      disableBeacon: true,
    },
  ];

  const EXPLAIN_LIST = [
    dict?.explain_pain,
    dict?.explain_hungry,
    dict?.explain_bc,
    dict?.explain_hc,
  ];

  const handleIsCorrectChange = (e) => {
    setIsCorrect(e.target.value);
    setEmptyIsCorrect(false);
    setWrongReasons("");
  };

  const handleWrongReasonsChange = (e) => {
    setWrongReasons(e.target.value);
    setEmptyWrongReasons(false);
    setOtherReasons("");
  };

  const handleFeedbackChange = (e) => {
    setFeedback(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsSubmit(true);
    setOpen(true);

    let reason = wrongReasons;
    if (wrongReasons === "others") {
      reason = wrongReasons + "/" + otherReasons;
    }

    let loc = location;
    if (loc === "others") {
      loc = loc + "/" + locationOther;
    }

    try {
      const { data: resp, error } = await supabase
        .from("GuestRecord")
        .update({
          reason: reason,
          correct: isCorrect,
          location: loc,
          updated_at: new Date(),
        })
        .eq("id", data.record.id)
        .select();

      if (error) {
        throw error;
      }

      return resp;
    } catch (error) {
      console.error("Error adding item", error);
    } finally {
    }
  };

  useEffect(() => {
    switch (data.language) {
      case "en":
        setDict(EnDict);
        break;
      case "zh":
        setDict(CnDict);
        break;
      case "ar":
        setDict(ArDict);
        break;
      default:
        setDict(EnDict);
    }
  }, [data.language]);

  const removeRecordings = async (filename) => {
    try {
      const filename = data?.record.recordings;
      const { data: resp, error } = await supabase.storage
        .from("guest")
        .remove(`${data?.record.user_id}/${data?.record.recordings}`);
      if (error) {
        throw error;
      }
      return filename;
    } catch (error) {
      console.error("Error adding item", error);
    } finally {
    }
  };

  async function getRecordings() {
    try {
      let { data: url, error } = await supabase.storage
        .from("guest")
        .download(`${data?.record.user_id}/${data?.record.recordings}`);

      if (error) {
        throw error;
      }
      return url;
    } catch (error) {
      console.error("Error adding item", error);
    } finally {
    }
  }

  async function deleteRecords() {
    try {
      const { error } = await supabase
        .from("GuestRecord")
        .delete()
        .eq("id", data.record.id);

      if (error) {
        throw error;
      }
      return data;
    } catch (error) {
      console.error("Error adding item", error);
    } finally {
    }
  }

  useEffect(() => {
    getRecordings().then((resp) => {
      setRecordUrl(URL.createObjectURL(resp));
    });
  }, []);

  const handleDelete = () => {
    deleteRecords();
    removeRecordings();
    navigate("/ghomepage");
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "auto",
    },
    overlay: {},
  };

  const TYPE_LIST = [
    dict?.pain,
    dict?.hungry,
    dict?.breathing_challenge,
    dict?.hearing_challenge,
  ];

  const REASON_LIST = [
    dict?.sleepiness,
    dict?.hunger_milk,
    dict?.hunger_solid_food,
    dict?.diaper_change_urine,
    dict?.diaper_change_bowel_movement,
    dict?.difficulty_breathing,
    dict?.internal_pain,
    dict?.external_pain,
    dict?.physical_discomfort,
    dict?.unmet_needs,
    dict?.lack_of_security,
  ];

  useEffect(() => {
    if (data.guide == true) {
      setRunTour(true);
      setData((prev) => ({
        ...prev,
        guide: false,
      }));
    }
  }, [data.guide]);

  const handleJoyrideCallback = (data) => {
    const { action, index, type } = data;

    if (type === "step:after" || type === "tooltip:next") {
      setStepIndex(index + (action === "prev" ? -1 : 1));
    }

    if (type === "tour:end") {
      setRunTour(false);
      setStepIndex(0);
    }
  };

  return (
    <div className="grespage__wrapper">
      <Joyride
        steps={steps}
        run={runTour}
        stepIndex={stepIndex}
        continuous
        showSkipButton
        callback={handleJoyrideCallback}
        locale={{
          back: dict?.back_step,
          close: dict?.close,
          last: dict?.last,
          next: dict?.next,
          open: dict?.open_dialog,
          skip: dict?.skip,
        }}
        styles={{
          options: {
            zIndex: 10000,
          },
        }}
      />
      <Modal
        isOpen={open}
        onClose={() => setOpen(false)}
        center
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        showCloseIcon={true}
        style={customStyles}
        container={myRef.current}
      >
        {isSubmit && (
          <div
            className={`customModal__button-wrapper ${
              data.language == "ar" ? "arabic" : ""
            }`}
          >
            <p>{dict?.thank_you}</p>
            <button
              className="gresultpage__button"
              onClick={() => {
                setOpen(false);
                setToggleDisplay(true);
              }}
            >
              <FontAwesomeIcon color="black" icon={faEdit} size="2x" />{" "}
              <span>{dict?.edit_feedback}</span>
            </button>
            <button
              className="gresultpage__button"
              onClick={() => {
                setOpen(false);
                setData({ language: data.language });
                navigate("/ghomepage");
              }}
            >
              <FontAwesomeIcon color="black" icon={faRotateRight} size="2x" />{" "}
              <span>{dict?.record_again}</span>
            </button>
          </div>
        )}
        {!isSubmit && (
          <div
            className={`customModal__button-wrapper ${
              data.language == "ar" ? "arabic" : ""
            }`}
          >
            <p>{dict?.are_you_sure_delete}</p>
            <button
              className="gresultpage__button"
              onClick={() => {
                setOpen(false);
              }}
            >
              <FontAwesomeIcon color="black" icon={faSquareXmark} size="2x" />{" "}
              <span>{dict?.cancel}</span>
            </button>
            <button
              className="gresultpage__button"
              onClick={(rec) => {
                setOpen(false);
                handleDelete();
              }}
            >
              <svg
                width="30"
                height="30"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.30638 1.26577C7.45267 0.998906 7.73279 0.833008 8.03712 0.833008H11.9905C12.2974 0.833008 12.5794 1.00166 12.7246 1.272L13.8317 3.33301H18.3334C18.7936 3.33301 19.1667 3.7061 19.1667 4.16634C19.1667 4.62658 18.7936 4.99967 18.3334 4.99967H17.0834V18.333C17.0834 18.7932 16.7103 19.1663 16.25 19.1663H3.75004C3.2898 19.1663 2.91671 18.7932 2.91671 18.333V4.99967H1.66671C1.20647 4.99967 0.833374 4.62658 0.833374 4.16634C0.833374 3.7061 1.20647 3.33301 1.66671 3.33301H6.17319L7.30638 1.26577ZM9.16671 8.33301C9.16671 7.87277 8.79361 7.49967 8.33337 7.49967C7.87314 7.49967 7.50004 7.87277 7.50004 8.33301V13.7497C7.50004 14.2099 7.87314 14.583 8.33337 14.583C8.79361 14.583 9.16671 14.2099 9.16671 13.7497V8.33301ZM11.6667 7.49967C12.1269 7.49967 12.5 7.87277 12.5 8.33301V13.7497C12.5 14.2099 12.1269 14.583 11.6667 14.583C11.2065 14.583 10.8334 14.2099 10.8334 13.7497V8.33301C10.8334 7.87277 11.2065 7.49967 11.6667 7.49967Z"
                  fill="black"
                  fill-opacity="0.85"
                />
              </svg>
              <span>{dict?.delete}</span>
            </button>
          </div>
        )}
      </Modal>
      <h1>{dict?.analyzed_result}</h1>
      {data &&
      data.result &&
      data.result.data.probs.length > 0 &&
      data.result.data.probs[0].length > 0 ? (
        <div className="gresult__wrapper">
          {(() => {
            let result = data.result.data.probs[0];
            let threeTotal = 0;
            let resultList = TYPE_LIST.map((name, i) => {
              let percent = 0;
              let _percent = parseInt((result[i] * 100).toFixed(0));
              if (i < TYPE_LIST.length - 1) {
                threeTotal += _percent || 0;
                percent = _percent;
              } else {
                percent = 100 - threeTotal;
              }
              return {
                name,
                percent,
                explain: EXPLAIN_LIST[i],
              };
            }).sort((a, b) => b.percent - a.percent);
            return (
              <div>
                {resultList.map((item, index) => (
                  <PercentItem
                    key={index}
                    name={item.name}
                    percent={item.percent}
                    isResult={true}
                    explain={item.explain}
                  ></PercentItem>
                ))}
              </div>
            );
          })()}
        </div>
      ) : (
        <p>{dict?.no_data_available}</p>
      )}
      <div className="information__form">
        <label>
          <span>{dict?.optional_fill_in}</span>
          <Switch
            onChange={() => setToggleDisplay(!toggleDisplay)}
            checked={toggleDisplay}
            className="react-switch"
          />
        </label>
      </div>
      <form className="gresultpage__form" onSubmit={handleSubmit}>
        {toggleDisplay && (
          <div>
            <div className="res__input-group">
              <label>{dict?.location}</label>
              <select
                name="location"
                value={location}
                ref={locationRef}
                onChange={(e) => {
                  setLocation(e.target.value);
                  setEmptyLocation(false);
                }}
              >
                <option value="" disabled>
                  {dict?.please_select}
                </option>
                <option value="daycare_centre">{dict?.location_daycare}</option>
                <option value="home">{dict?.location_home}</option>
                <option value="hospital/clinic">{dict?.location_hc}</option>
                <option value="park/playground">{dict?.location_pp}</option>
                <option value="street">{dict?.location_street}</option>
                <option value="others">{dict?.others}</option>
              </select>
              {emptyLocation && (
                <div className="invalid-feedback">
                  {dict?.error_please_state}
                </div>
              )}
            </div>
            {location === "others" && (
              <div className="res__input-group">
                <label>{dict?.please_state}</label>
                <input
                  type="text"
                  name="otherLocation"
                  value={locationOther}
                  ref={locationOtherRef}
                  onChange={(e) => {
                    setEmptyLocationOther(false);
                    setLocationOther(e.target.value);
                  }}
                />
                {emptyLocationOther && (
                  <div className="invalid-feedback">
                    {dict?.error_please_state}
                  </div>
                )}
              </div>
            )}
            {isCorrect === "wrong" && (
              <div className="input-group">
                <label>{dict?.reason}</label>
                <select
                  value={wrongReasons}
                  ref={wrongReasonsRef}
                  onChange={handleWrongReasonsChange}
                >
                  <option value="" disabled>
                    {dict?.please_select}
                  </option>
                  {REASON_LIST.map((type, index) => (
                    <option key={index} value={type}>
                      {type}
                    </option>
                  ))}
                  <option value="others">{dict?.others}</option>
                </select>
                {emptyWrongReasons && (
                  <div className="invalid-feedback">
                    {dict?.error_please_select}
                  </div>
                )}
              </div>
            )}
            {wrongReasons === "others" && (
              <div className="input-group">
                <label>{dict?.error_please_state}</label>
                <input
                  type="text"
                  name="otherReason"
                  value={otherReasons}
                  ref={othersRef}
                  onChange={(e) => {
                    setEmptyOthers(false);
                    setOtherReasons(e.target.value);
                  }}
                />
                {emptyOthers && (
                  <div className="invalid-feedback">
                    {dict?.error_please_state}
                  </div>
                )}
              </div>
            )}
          </div>
        )}

        <div className="grespage__button-wrapper">
          <button
            className="grespage__button"
            type="button"
            onClick={(event) => {
              handleSubmit(event);
            }}
          >
            <span>{dict?.submit}</span>
          </button>
          <button
            className="grespage__button-red"
            type="button"
            onClick={() => {
              setOpen(true);
              setIsSubmit(false);
            }}
          >
            <span>{dict?.delete}</span>
          </button>
        </div>
      </form>
      <div className="grespage__audio">
        <audio id="audio" width="100%" src={recordUrl} controls></audio>
      </div>
    </div>
  );
};

export default ResPage;
