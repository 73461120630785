import React, { useEffect, useState, useContext } from "react";
import "./BoxInput.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import zh from "react-phone-number-input/locale/zh";
import en from "react-phone-number-input/locale/en";
import EnDict from "../../assets/dict/EnDict.json";
import CnDict from "../../assets/dict/CnDict.json";
import ArDict from "../../assets/dict/ArDict.json";

import { CurrentDataContext } from "../../App";

const BoxInput = ({
  leftIcon,
  rightIcon,
  placeholder,
  name,
  type,
  handleChange,
  handleRightIconClick,
  phone,
  setPhone,
  error,
  errorText,
  setError,
  ref,
}) => {
  const { data, setData } = useContext(CurrentDataContext);

  const [language, setLanguage] = useState();
  const [dict, setDict] = useState();

  useEffect(() => {
    console.log("lang", data.language);
    // setLanguage(data.language);
    switch (data.language) {
      case "en":
        setDict(EnDict);
        break;
      case "zh":
        setDict(CnDict);
        break;
      case "ar":
        setDict(ArDict);
        break;
      default:
        setDict(EnDict);
    }
  }, [data.language]);

  return (
    <div className="input__box">
      <div className="input__box-wrapper">
        <div className="div__righticon">
          <FontAwesomeIcon
            className="input__icon"
            color="gray"
            icon={leftIcon}
            size="1.5x"
          />
        </div>
        {name === "phone" && (
          <div className="phone__input-wrapper">
            <PhoneInput
              className="phone__input"
              size={"20px"}
              style={{ width: "100%" }}
              international
              defaultCountry={data.language === "zh" ? "CN" : ""}
              labels={data.language === "en" ? en : zh}
              placeholder={dict?.enter_phone_number}
              value={phone}
              onChange={(e) => {
                setPhone(e);
                setError(false);
              }}
            />
          </div>
        )}
        {name !== "phone" && (
          <input
            placeholder={placeholder}
            name={name}
            type={type}
            onChange={(e) => {
              handleChange(e);
              setError(false);
            }}
            ref={ref}
          />
        )}

        {rightIcon && (
          <div className="div__righticon">
            <FontAwesomeIcon
              className="input__righticon"
              color="gray"
              icon={rightIcon}
              size="1.5x"
              fixedWidth
              onClick={handleRightIconClick}
            />
          </div>
        )}
      </div>
      {error && <div className="invalid-feedback">{errorText}</div>}
    </div>
  );
};

export default BoxInput;
