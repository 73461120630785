import React, { useState, useContext, useRef, useEffect } from "react";

import "./HistoryList.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faTrash,
  faSquareCheck,
  faSquareXmark,
  faHouse,
} from "@fortawesome/free-solid-svg-icons";
import { CurrentDataContext } from "../../App";
import { useNavigate } from "react-router-dom";
import { supabase } from "../../client";
import Modal from "react-modal";
import EnDict from "../../assets/dict/EnDict.json";
import CnDict from "../../assets/dict/CnDict.json";
import ArDict from "../../assets/dict/ArDict.json";

const HistoryList = ({
  setChange,
  items,
  selectedRecord,
  setSelectedRecord,
  viewAll,
}) => {
  const [selected, setSelected] = useState(null);
  const { data, setData } = useContext(CurrentDataContext);
  const navigate = useNavigate();
  const [delId, setDelId] = useState();
  const [open, setOpen] = useState(false);
  const myRef = useRef(null);
  const [dict, setDict] = useState();

  // const TYPE_LIST = [dict?.pain, dict?.hungry, dict?.asphyxia, dict?.deaf];
  const TYPE_LIST = [
    dict?.pain,
    dict?.hungry,
    dict?.breathing_challenge,
    dict?.hearing_challenge,
  ];
  useEffect(() => {
    console.log("lang", data.language);
    // setLanguage(data.language);
    switch (data.language) {
      case "en":
        setDict(EnDict);
        break;
      case "zh":
        setDict(CnDict);
        break;
      case "ar":
        setDict(ArDict);
        break;
      default:
        setDict(EnDict);
    }
  }, [data.language]);

  const handleClick = (index) => {
    setSelected(index);
    setSelectedRecord(index);
  };

  const handleEdit = (rec) => {
    setData((prev) => ({
      ...prev,
      rec: rec,
    }));
    navigate("/edit");
  };

  async function deleteRecords() {
    try {
      const { error } = await supabase
        .from("Record")
        .delete()
        .eq("id", delId);

      if (error) {
        throw error;
      }
      setChange(true);
      return data;
    } catch (error) {
      console.error("Error adding item", error);
    } finally {
    }
  }

  async function removeRecordings(delItem) {
    try {
      //   setLoading(true);
      console.log("remove this:", delItem);
      const filename = delItem.record.recordings;
      console.log("delete:", filename);
      const { data: resp, error } = await supabase.storage
        .from("letbabytalk")
        .remove(`${delItem.record.user_id}/${filename}`);
      if (error) {
        throw error;
      }
      return filename;
    } catch (error) {
      console.error("Error adding item", error);
    } finally {
      //   setLoading(false);
    }
  }

  const handleDelete = () => {
    deleteRecords().then((resp) => {
      console.log("del", resp);
      removeRecordings(resp);
    });
  };

  function largestIndex(dataArray) {
    let max = dataArray[0];
    let maxIndex = 0;

    // Iterate over the array to find the largest value and its index
    for (let i = 1; i < dataArray.length; i++) {
      if (dataArray[i] > max) {
        max = dataArray[i];
        maxIndex = i;
      }
    }
    max = parseInt((max * 100).toFixed(0));
    return [max, maxIndex];
  }

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "auto",
    },
    overlay: {
      // backgroundColor: "transparent",
    },
  };

  return (
    <div className="history-list">
      <Modal
        isOpen={open}
        onClose={() => setOpen(false)}
        center
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        showCloseIcon={true}
        style={customStyles}
        container={myRef.current}
      >
        <div
          className={`customModal__button-wrapper ${
            data.language == "ar" ? "arabic" : ""
          }`}
        >
          <p>{dict?.are_you_sure_delete}</p>
          <button
            className="gresultpage__button"
            onClick={() => {
              setOpen(false);
              setDelId("");
            }}
          >
            <FontAwesomeIcon color="black" icon={faSquareXmark} size="2x" />{" "}
            <span>{dict?.cancel}</span>
          </button>
          <button
            className="gresultpage__button"
            onClick={(rec) => {
              setOpen(false);
              handleDelete();
            }}
          >
            {/* <FontAwesomeIcon color="black" icon={faTrash} size="2x" /> */}
            <svg
              width="30"
              height="30"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.30638 1.26577C7.45267 0.998906 7.73279 0.833008 8.03712 0.833008H11.9905C12.2974 0.833008 12.5794 1.00166 12.7246 1.272L13.8317 3.33301H18.3334C18.7936 3.33301 19.1667 3.7061 19.1667 4.16634C19.1667 4.62658 18.7936 4.99967 18.3334 4.99967H17.0834V18.333C17.0834 18.7932 16.7103 19.1663 16.25 19.1663H3.75004C3.2898 19.1663 2.91671 18.7932 2.91671 18.333V4.99967H1.66671C1.20647 4.99967 0.833374 4.62658 0.833374 4.16634C0.833374 3.7061 1.20647 3.33301 1.66671 3.33301H6.17319L7.30638 1.26577ZM9.16671 8.33301C9.16671 7.87277 8.79361 7.49967 8.33337 7.49967C7.87314 7.49967 7.50004 7.87277 7.50004 8.33301V13.7497C7.50004 14.2099 7.87314 14.583 8.33337 14.583C8.79361 14.583 9.16671 14.2099 9.16671 13.7497V8.33301ZM11.6667 7.49967C12.1269 7.49967 12.5 7.87277 12.5 8.33301V13.7497C12.5 14.2099 12.1269 14.583 11.6667 14.583C11.2065 14.583 10.8334 14.2099 10.8334 13.7497V8.33301C10.8334 7.87277 11.2065 7.49967 11.6667 7.49967Z"
                fill="black"
                fill-opacity="0.85"
              />
            </svg>
            <span>{dict?.delete}</span>
          </button>
        </div>
      </Modal>
      {items &&
        items.length > 0 &&
        items.slice(0, viewAll ? items.length : 5).map((item, index) => {
          const [maxValue, maxIndex] = largestIndex(
            JSON.parse(item.prediction)
          );
          console.log("yyyy", item);

          const dateObject = new Date(item.created_at);
          const formattedDate = dateObject.toISOString().split("T")[0];
          const formattedTime = dateObject.toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          });

          // console.log(`${formattedDate} ${formattedTime}`);

          return (
            <div
              key={index}
              className={`item ${selected === index ? "selected" : ""}`}
              onClick={() => handleClick(index)}
            >
              <div className="item-name">
                {data.showPrediction == true
                  ? TYPE_LIST[maxIndex]
                  : `${dict?.record} ${index + 1}`}
                {/* Record {index + 1} */}
                <button
                  className="button-transparent"
                  onClick={() => handleEdit(item)}
                >
                  <FontAwesomeIcon color="#00B407" icon={faEdit} size="1x" />
                </button>
                <div className="item-confidence">
                  {maxValue}% {dict?.confidence}
                </div>
              </div>
              <div className="item-time">
                {formattedDate} {formattedTime}
                <div className="item-confidence" style={{ marginTop: "0px" }}>
                  {dict?.uploaded_at}{" "}
                </div>
              </div>
              <div>
                <button
                  className="button-transparent"
                  onClick={() => {
                    setOpen(true);
                    setDelId(item.id);
                  }}
                >
                  {/* <FontAwesomeIcon color="black" icon={faTrash} size="2x" /> */}
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.30638 1.26577C7.45267 0.998906 7.73279 0.833008 8.03712 0.833008H11.9905C12.2974 0.833008 12.5794 1.00166 12.7246 1.272L13.8317 3.33301H18.3334C18.7936 3.33301 19.1667 3.7061 19.1667 4.16634C19.1667 4.62658 18.7936 4.99967 18.3334 4.99967H17.0834V18.333C17.0834 18.7932 16.7103 19.1663 16.25 19.1663H3.75004C3.2898 19.1663 2.91671 18.7932 2.91671 18.333V4.99967H1.66671C1.20647 4.99967 0.833374 4.62658 0.833374 4.16634C0.833374 3.7061 1.20647 3.33301 1.66671 3.33301H6.17319L7.30638 1.26577ZM9.16671 8.33301C9.16671 7.87277 8.79361 7.49967 8.33337 7.49967C7.87314 7.49967 7.50004 7.87277 7.50004 8.33301V13.7497C7.50004 14.2099 7.87314 14.583 8.33337 14.583C8.79361 14.583 9.16671 14.2099 9.16671 13.7497V8.33301ZM11.6667 7.49967C12.1269 7.49967 12.5 7.87277 12.5 8.33301V13.7497C12.5 14.2099 12.1269 14.583 11.6667 14.583C11.2065 14.583 10.8334 14.2099 10.8334 13.7497V8.33301C10.8334 7.87277 11.2065 7.49967 11.6667 7.49967Z"
                      fill="black"
                      fill-opacity="0.85"
                    />
                  </svg>
                </button>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default HistoryList;
