import React, { useState, useContext, useEffect } from "react";
import { CurrentDataContext } from "../../App";
import { Link, useNavigate } from "react-router-dom";
import { supabase } from "../../client";
import HistoryList from "../../components/HistoryList/HistoryList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faContactBook } from "@fortawesome/free-solid-svg-icons";
import Joyride from "react-joyride";
import { useLocalStorage } from "../../hooks/useLocalStorage";

import "./HomePage.css";

import MediaControl from "../../components/MediaControl/MediaControl";
import AudioComponent from "../../components/AudioComponent/AudioComponent";

import EnDict from "../../assets/dict/EnDict.json";
import CnDict from "../../assets/dict/CnDict.json";
import ArDict from "../../assets/dict/ArDict.json";

const key = "auth";

const HomePage = ({ token, change, setChange }) => {
  const { data, setData } = useContext(CurrentDataContext);
  const { getItem, setItem, removeItem } = useLocalStorage(key);
  const {
    getItem: getShowPredictionItem,
    setItem: setShowPredictionItem,
    removeItem: removeShowPredictionItem,
  } = useLocalStorage("showPrediction");

  console.log(token);
  const navigate = useNavigate();
  const [babies, setBabies] = useState();
  const [selectedBaby, setSelectedBaby] = useState();
  console.log("homepage", data);
  const [selectedBabyIndex, setSelectedBabyIndex] = useState(
    data?.babyIndex || 0
  );
  const [records, setRecords] = useState();
  const [recordUrl, setRecordUrl] = useState("");
  const [selectedRecord, setSelectedRecord] = useState();
  const [viewAll, setViewAll] = useState(false);

  const [dict, setDict] = useState();
  const [runTour, setRunTour] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);

  useEffect(() => {
    async function refreshToken() {
      const sessionExpiredAt = getItem().session.expires_at;
      // console.log("see exp,", sessionExpiredAt);
      const currentTime = Math.floor(Date.now() / 1000);
      if (currentTime >= sessionExpiredAt) {
        console.log("Session expired");
        console.log("getSession", await supabase.auth.getSession());
        const { data: resp, error } = await supabase.auth.getSession();
        if (error) {
          console.log("error refresh", error);
        }
        console.log("new token", resp);
        setItem(resp);
        sessionStorage.setItem("token", JSON.stringify(resp));
        setData((prev) => ({
          ...prev,
          token: resp.session?.access_token,
        }));
      } else {
        console.log("Session not expired.");
      }
    }

    // refreshNewToken(getItem().session);
    refreshToken();
  }, []);

  useEffect(() => {
    if (data?.newUser == true) {
      setRunTour(true);
      setData((prev) => ({
        ...prev,
        newUser: false,
      }));
    }
  }, []);

  const steps = [
    {
      target: ".navbar__logo-guide",
      content: dict?.guide_guide,
      placement: "bottom",
      disableBeacon: true,
    },
    {
      target: ".homepage__wrapper",
      content: dict?.guide_homepage,
      placement: "bottom",
      disableBeacon: true,
    },
    {
      target: ".baby__display-wrapper",
      content: dict?.guide_baby,
      placement: "bottom",
      disableBeacon: true,
    },
    {
      target: ".homepage__hist-wrapper",
      content: dict?.guide_history,
      placement: "bottom",
      disableBeacon: true,
    },
    {
      target: ".audio_bar",
      content: dict?.guide_audio,
      placement: "bottom",
      disableBeacon: true,
    },
    {
      target: ".addrecord__button-wrapper",
      content: dict?.guide_add_recordings,
      placement: "bottom",
      disableBeacon: true,
    },
  ];

  function formatDate(date) {
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2); // getMonth() is zero-based
    const day = ("0" + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }

  const addBaby = async (userId, name, dob, gender) => {
    try {
      const { data: resp, error } = await supabase
        .from("Baby")
        .insert([
          {
            user_id: userId,
            name,
            dob,
            gender,
            created_at: new Date(),
            updated_at: new Date(),
          },
        ])
        .select();

      if (error) {
        throw error;
      }
      return resp;
    } catch (error) {
      console.error("Error adding item", error);
    } finally {
    }
  };

  useEffect(() => {
    const showPrediction = getShowPredictionItem();
    if (showPrediction != null) {
      setData((prev) => ({
        ...prev,
        showPrediction: showPrediction,
      }));
    } else {
      setData((prev) => ({
        ...prev,
        showPrediction: false,
      }));
    }
  }, []);

  async function handleAddRecord() {
    console.log("data", data);
    if (babies.length <= 0) {
      let resp = await addBaby(
        data.userId,
        "Baby 1",
        formatDate(new Date()),
        "male",
        new Date(),
        new Date()
      );
      console.log("respsp", resp);
      setData((prev) => ({
        ...prev,
        baby: resp[0],
        babyIndex: 0,
      }));
      sessionStorage.setItem("baby", JSON.stringify(resp[0]));
    } else {
      setData((prev) => ({
        ...prev,
        baby: selectedBaby,
        babyIndex: selectedBabyIndex,
      }));
      sessionStorage.setItem("baby", JSON.stringify(selectedBaby));
    }
    navigate("/add");
  }

  useEffect(() => {
    console.log("lang", data.language);
    // setLanguage(data.language);
    switch (data.language) {
      case "en":
        setDict(EnDict);
        break;
      case "zh":
        setDict(CnDict);
        break;
      case "ar":
        setDict(ArDict);
        break;
      default:
        setDict(EnDict);
    }
  }, [data.language]);

  async function getRecordings() {
    // console.log("getting ", records[selectedRecord]?.recordings);
    try {
      let { data: url, error } = await supabase.storage
        .from("letbabytalk")
        .download(`${data.userId}/${records[selectedRecord]?.recordings}`);
      // .getPublicUrl(`recordings/${records[selectedRecord]?.recordings}`);
      //   const { data: resp, error } = await supabase.storage
      //     .from("letbabytalk")
      //     .download(`recordings/${data?.record.recordings}`);

      if (error) {
        throw error;
      }
      return url;
    } catch (error) {
      console.error("Error adding item", error);
    } finally {
    }
  }

  useEffect(() => {
    if (records) {
      getRecordings().then((resp) => {
        // console.log("recordings?", resp);
        if (resp) {
          setRecordUrl(URL.createObjectURL(resp));
        }
      });
    }
  }, [selectedRecord]);

  // useEffect(() => {
  //   // console.log("run getting", selectedRecord);
  //   // console.log("rec", records[selectedRecord]?.recordings);
  //   if (records) {
  //     getRecordings().then((resp) => setRecordUrl(resp.publicUrl));
  //   }

  //   //   getRecordings().then((resp) => setRecordUrl(resp.publicUrl));
  // }, [selectedRecord]);

  function handleAddBaby() {
    setData((prev) => ({
      ...prev,
      status: "add",
    }));
    navigate("/baby");
  }

  function handleEditBaby() {
    setData((prev) => ({
      ...prev,
      status: "edit",
      baby: selectedBaby,
      babyIndex: selectedBabyIndex,
    }));
    navigate("/baby");
  }

  async function getBabies() {
    try {
      let { data, error } = await supabase.from("Baby").select("*");
      setBabies(data);

      if (error) {
        throw error;
      }
      return data;
    } catch (error) {
      console.error("Error adding item", error);
    } finally {
    }
  }

  async function getRecords(baby) {
    console.log("test", baby);
    try {
      let { data: resp, error } = await supabase
        .from("Record")
        .select("*")
        .eq("baby_id", baby?.id)
        .order("created_at", { ascending: false });
      setRecords(resp);

      if (error) {
        throw error;
      }
      return data;
    } catch (error) {
      console.error("Error adding item", error);
    } finally {
    }
  }

  useEffect(() => {
    console.log("where", babies, "select", selectedBabyIndex);
    if (babies && babies.length > 0) {
      if (selectedBabyIndex !== null) {
        setSelectedBaby(babies[selectedBabyIndex]);
        getRecords(babies[selectedBabyIndex]);
        setData((prev) => ({
          ...prev,
          baby: babies[selectedBabyIndex],
          babyIndex: selectedBabyIndex,
        }));
      } else {
        setSelectedBabyIndex(0);
        setSelectedBaby(babies[0]);
        getRecords(babies[0]);
        setData((prev) => ({
          ...prev,
          baby: babies[0],
          babyIndex: 0,
        }));
      }
    }
  }, [babies]);

  useEffect(() => {
    if (change === true) {
      getBabies();
      getRecords(selectedBaby);
      setChange(false);
    }
  }, [change]);

  const handleBabyChange = (event) => {
    const selectedIndex = event.target.value;
    setSelectedBabyIndex(selectedIndex);
    setSelectedBaby(babies[selectedIndex]);
    setRecords(babies[selectedIndex]);
    setChange(true);
    setData((prev) => ({
      ...prev,
      baby: babies[selectedIndex],
      babyIndex: selectedIndex,
    }));
  };

  useEffect(() => {
    if (data.guide == true) {
      console.log("run");
      setRunTour(true);
      setData((prev) => ({
        ...prev,
        guide: false,
      }));
    }
  }, [data.guide]);

  function calculateWeeks(dob) {
    // Convert DOB to milliseconds
    var dobMillis = new Date(dob).getTime();

    // Calculate current date in milliseconds
    var currentDateMillis = Date.now();

    // Calculate the difference in milliseconds
    var differenceMillis = currentDateMillis - dobMillis;

    // Convert milliseconds to weeks
    var weeks = Math.floor(differenceMillis / (1000 * 60 * 60 * 24 * 7));

    return weeks;
  }

  useEffect(() => {
    setData((prev) => ({
      ...prev,
      fullname: token.user.user_metadata.full_name,
      userId: token.user.id,
      //   userId: "yukang",
    }));
    // fetchData();
    getBabies();
  }, []);

  const handleJoyrideCallback = (data) => {
    const { action, index, type } = data;
    console.log("act", action, index, type);

    if (type === "step:after" || type === "tooltip:next") {
      setStepIndex(index + (action === "prev" ? -1 : 1));
    }

    if (type === "tour:end") {
      setRunTour(false);
      setStepIndex(0);
    }
  };

  return (
    <div className="homepage__wrapper">
      <Joyride
        steps={steps}
        run={runTour}
        stepIndex={stepIndex}
        continuous
        showSkipButton
        callback={handleJoyrideCallback}
        locale={{
          back: dict?.back_step,
          close: dict?.close,
          last: dict?.last,
          next: dict?.next,
          open: dict?.open_dialog,
          skip: dict?.skip,
        }}
        styles={{
          options: {
            zIndex: 10000,
          },
        }}
      />
      {!viewAll && (
        <div className="text-overflow">
          <h1>
            {dict?.welcome_back}{" "}
            {data && data.fullname && data.fullname.toUpperCase()}
          </h1>
        </div>
      )}
      {!viewAll && (
        <div className="baby__display-wrapper">
          <div className="baby__display">
            <select
              name="babyDisplay"
              value={selectedBabyIndex}
              onChange={(e) => {
                // setGender(e.target.value);
                // setEmptyGender(false);
                handleBabyChange(e);
              }}
            >
              {babies?.map((baby, index) => (
                <option key={index} value={index}>
                  <div className="bb">{baby.name.toUpperCase()}</div>
                </option>
              ))}
              {babies && babies.length < 1 && (
                <option selected disabled>
                  {dict?.add_baby}
                </option>
              )}
            </select>

            <div className="hpbaby__display-text">
              <h3>{dict?.age}</h3>
              <h3>{calculateWeeks(selectedBaby?.dob)}</h3>{" "}
              <p
                style={{
                  fontSize: "0.8rem",
                  margin: "0.1rem 0.5rem 0.1rem 0.2rem",
                }}
              >
                {dict?.weeks}
              </p>
              <h3>
                {selectedBaby?.gender === "male"
                  ? dict?.male.toUpperCase()
                  : dict?.female.toUpperCase()}
              </h3>
              <p
                style={{
                  fontSize: "1.0rem",
                  margin: "0rem 0.2rem",
                }}
              >
                •
              </p>
              <h3>{records ? records.length : 0}</h3>
              <p
                style={{
                  fontSize: "0.8rem",
                  margin: "0.1rem 0.5rem 0.1rem 0.2rem",
                }}
              >
                {/* {selectedBaby?.gender.toUpperCase()} &#8729;{" "} */}
                {dict?.records}
              </p>
            </div>
          </div>
          <div className="baby__display-button">
            <button onClick={handleAddBaby}>
              <FontAwesomeIcon color="#737373" icon={faContactBook} size="1x" />
              &nbsp;{dict?.add}
            </button>
            <button
              onClick={handleEditBaby}
              disabled={babies && babies.length < 1}
            >
              <FontAwesomeIcon color="#737373" icon={faEdit} size="1x" />
              &nbsp;{dict?.edit}
            </button>
          </div>
        </div>
      )}
      <div className="homepage__hist-wrapper">
        <h1>{dict?.history}</h1>
        {viewAll ? (
          <Link onClick={() => setViewAll(!viewAll)}>{dict?.back}</Link>
        ) : (
          <Link onClick={() => setViewAll(!viewAll)}>{dict?.view_all}</Link>
        )}
      </div>
      <div>
        {!records && <>{dict?.no_records}</>}
        {records && (
          <HistoryList
            setChange={setChange}
            items={records}
            selectedRecord={selectedRecord}
            setSelectedRecord={setSelectedRecord}
            viewAll={viewAll}
          ></HistoryList>
        )}
      </div>
      <div className="audio-wrapper">
        {
          <button
            className="addrecord__button-wrapper"
            type="submit"
            // disabled={babies && babies.length > 0 ? false : true}
            onClick={handleAddRecord}
          >
            {dict?.start_new_records}
            {/* {babies && babies.length > 0 ? (
          dict?.start_new_records
        ) : (
          <div className="div-red">{dict?.add_baby_to_start_recording}</div>
        )} */}
          </button>
        }

        <div style={{ padding: "0.2rem" }}></div>
        <audio
          id="audio"
          className="audio_bar"
          src={recordUrl}
          controls
        ></audio>
        {/* <MediaControl></MediaControl> */}
        {/* <AudioComponent src={recordUrl}></AudioComponent> */}
      </div>
    </div>
  );
};

export default HomePage;
