import React, { useState, useRef, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { supabase } from "../../client";
import {
  faEnvelope,
  faPhone,
  faArrowLeft,
  faLock,
  faEyeSlash,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BoxInput from "../../components/BoxInput/BoxInput";
import { CurrentDataContext } from "../../App";
import EnDict from "../../assets/dict/EnDict.json";
import CnDict from "../../assets/dict/CnDict.json";
import ArDict from "../../assets/dict/ArDict.json";

const ForgetPasswordPage = () => {
  let navigate = useNavigate();
  const { data, setData } = useContext(CurrentDataContext);

  const [language, setLanguage] = useState();
  const [dict, setDict] = useState();

  const [phone, setPhone] = useState();
  const emailRef = useRef();
  const [emptyEmail, setEmptyEmail] = useState();
  const [errorEmail, setErrorEmail] = useState();
  const phoneRef = useRef();
  const [emptyPhone, setEmptyPhone] = useState();

  const [signUpType, setSignUpType] = useState("email");
  const [formData, setFormData] = useState({
    phone: "",
    email: "",
    password: "",
  });

  useEffect(() => {
    console.log("lang", data.language);
    // setLanguage(data.language);
    switch (data.language) {
      case "en":
        setDict(EnDict);
        break;
      case "zh":
        setDict(CnDict);
        break;
      case "ar":
        setDict(ArDict);
        break;
      default:
        setDict(EnDict);
    }
  }, [data.language]);

  function handleChange(event) {
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [event.target.name]: event.target.value,
      };
    });
  }

  async function handleSubmit(e) {
    e.preventDefault();
    if (signUpType === "phone") {
      console.log("phone", phone);
      try {
        const { resp, error } = await supabase.auth.signInWithOtp({
          phone: phone,
        });
        if (error) throw error;
      } catch (error) {
        alert(error);
      }
      setData((prev) => ({
        ...prev,
        signUpType: "phone",
        phone: phone,
        from: "forgetpassword",
      }));

      navigate("/verify");
    } else {
      if (formData.email === "") {
        setEmptyEmail(true);
        // emailRef.current.focus();
        return;
      }
      let emailRegex = /[a-z0-9]+@\w+\.[a-z]{2,3}/;
      if (!emailRegex.test(formData.email)) {
        // invalid email
        setErrorEmail(true);
        // emailRef.current.focus();
        return;
      }
      const { resp, error } = await supabase.auth.signInWithOtp({
        email: formData.email,
      });
      setData((prev) => ({
        ...prev,
        signUpType: "email",
        email: formData.email,
        from: "forgetpassword",
      }));
      alert("email send");
      navigate("/verify");
    }
  }

  return (
    <div className="login__wrapper">
      <div className="back__wrapper">
        <FontAwesomeIcon
          className="input__icon"
          color="black"
          icon={faArrowLeft}
          onClick={() => navigate("/signin")}
          size="2x"
        />
      </div>
      <h1>{dict?.forget_password}</h1>
      <h3>{dict?.enter_registered_email_phone}</h3>
      <div className="login__button-side">
        <button
          onClick={() => {
            setSignUpType("email");
          }}
        >
          {dict?.email}
        </button>
        <button
          onClick={() => {
            setSignUpType("phone");
          }}
        >
          {dict?.phone}
        </button>
      </div>
      <form className="login__form-wrapper" onSubmit={handleSubmit}>
        {signUpType === "email" && (
          <BoxInput
            leftIcon={faEnvelope}
            name={"email"}
            placeholder={dict?.email}
            type={"text"}
            handleChange={handleChange}
            ref={emailRef}
            error={emptyEmail ? emptyEmail : errorEmail}
            errorText={
              emptyEmail ? dict?.error_enter_email : dict?.error_invalid_email
            }
            setError={emptyEmail ? setEmptyEmail : setErrorEmail}
          ></BoxInput>
        )}
        {signUpType === "phone" && (
          <BoxInput
            leftIcon={faPhone}
            name={"phone"}
            type="text"
            placeholder={dict?.phone}
            handleChange={handleChange}
            phone={phone}
            setPhone={setPhone}
            ref={phoneRef}
            error={emptyPhone}
            errorText={dict?.error_fill_in_valid_phone_number}
            setError={setEmptyPhone}
          ></BoxInput>
        )}
        <button className="login__button-wrapper" type="submit">
          {dict?.submit}
        </button>
      </form>
    </div>
  );
};

export default ForgetPasswordPage;
